import request from "./request";

export const getSettings = async () => {
  const response = await request("GET", "/settings");
  const data = await response.json();
  if (response.status === 200) {
    return data
  } else {
    return;
  }
};
