import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import ItemNotification from "../../Atoms/ItemNotification/ItemNotification";
import "./DrawerNotification.scss";
import Spinner from "../../Atoms/Spinner/Spinner";
// import useNotifications from "../../../customHooks/useNotifications";
import { getNotifications } from "../../../services/getNotifications";
import { saveNotifications } from "../../../redux/actionsCreators";
import { useDispatch } from "react-redux";

const INITIAL_PAGE = 0

const DrawerNotification = ({ setNotificationsCount, onClose, visible, setVisibleDrawer }) => {
  const [notifications, setNotifications] = useState([])
  const [loadingNotifications, setLoadingNotifications] = useState(false)
  const [loadingNotificationsPagination, setLoadingNotificationsPagination] = useState(false)
  const [notificationsError, setNotificationsError] = useState(null)
  const [totalNotifications, setTotalNotifications] = useState(null)
  const [page, setPage] = useState(INITIAL_PAGE)

  const dispatch = useDispatch()
  
  useEffect(() => {
    setLoadingNotifications(true)
    getNotifications({page: 0}).then(notifications => {
      console.log('notifications', notifications)
      setTotalNotifications(notifications.count)
      setNotifications(notifications.rows)
      const notificationsUnread = notifications.rows.filter(notification => notification.read !== true)
      setNotificationsCount(notificationsUnread.length)
      setLoadingNotifications(false)
      dispatch(saveNotifications({page: 0}))
    }).catch(error => {
      console.log('error', error)
      setNotificationsError(true)
      setLoadingNotifications(false)
    })
  }, [dispatch, setNotificationsCount])

  useEffect(() => {
    if(page === INITIAL_PAGE) return 
    setLoadingNotificationsPagination(true)
    getNotifications({page}).then(notifications => {
      console.log('notifications Pagination', notifications)
      setTotalNotifications(notifications.count)
      setNotifications(prevNotifications => prevNotifications.concat(notifications.rows))
      const notificationsUnread = notifications.rows.filter(notification => notification.read !== true)
      setNotificationsCount(prevCount => prevCount + notificationsUnread.length)
      setLoadingNotificationsPagination(false)
      // dispatch(saveNotifications({page: page}))
    }).catch(error => {
      console.log('error', error)
      setNotificationsError(true)
      setLoadingNotificationsPagination(false)
    })
  }, [dispatch, page, setNotificationsCount])
  
  // const { notifications, loadingNotifications, setReloadNotifications, totalNotifications, loadingNotificationsPagination, notificationsError, notificationsCount, setPage } = useNotifications();

  // useEffect(() => {
  //   setNotificationsCount(notificationsCount)
  //   console.log('render DrawerNotification')
  // }, [notificationsCount, setNotificationsCount])

  return (
    <Drawer
      title="Notificaciones"
      placement="right"
      closable={true}
      onClose={onClose}
      visible={visible}
      width={310}
    >
    {
      notificationsError ? 
      <p className="title-small-blue center-text pt-20">Error al obtener las notificaciones</p>
      :
      loadingNotifications ? (
        <Spinner small />
      ) : notifications.length < 1 ? (
        <p className="title-small-blue center-text pt-20">No tienes notificaciones.</p>
      ) : 
      (
        <>
        {notifications.map((notification) => (
          <ItemNotification key={notification.id} notification={notification} setVisibleDrawer={setVisibleDrawer}/>
        ))}
        {
          loadingNotificationsPagination 
          ? <div className="flex-center"><Spinner small_x2 /></div>
          : notifications.length !== totalNotifications && <p className="center-text link title-small-blue mt-20 mb-20" onClick={() => setPage(prevPage => prevPage + 1)}>Ver mas</p>
        }
        </>
      )
    }
    </Drawer>
  );
};

export default DrawerNotification;
