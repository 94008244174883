import React, {useState, useEffect} from 'react';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import {Radio} from 'antd';
import {withRouter, Redirect} from 'react-router-dom';
import './Step3.scss';
import ModalError from '../../Molecules/ModalError/ModalError';
// import ActionInput from '../../Atoms/ActionInput/ActionInput';
import {useSelector} from 'react-redux';
import {getResponsibleUser} from '../../../services/helpers';

function Step3(props) {
  const [players, setPlayers] = useState(props.step3Data ? props.step3Data.players : 1);
  const [player2, setPlayer2] = useState(props.step3Data ? props.step3Data.player2 : null);
  const [contentError, setContentError] = useState('');
  const [showModal, setShowModal] = useState(false);

  const user = useSelector(state => state.userReducer.userInfo)

  useEffect(()=> {
    props.setStep4Data(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const forward = () => {
    //validar que no está buscando a si mismo, es decir, que el logeado se busca a si mismo en los invitados
    if (players === 2 && !player2) {
      setContentError('Debe definir el Participante 2');
      setShowModal(true);
    } else {
      setShowModal(false);
      props.saveData({
        players, player2
      })
      props.history.push('/reservas/indoor-cycling/seleccion-bicicletas')
    }
  }
  const back = () => {
    props.history.push('/reservas/indoor-cycling/responsable')
  }

  let responsible = {};
  if (props.step1Data) {
    responsible = getResponsibleUser(props.step2Data.responsible, user)
  }

  return (
    <>
      {
      props.step1Data ? 
      <>
        <div className="container-reserve-indoor-step-3">
          <div className="container-reserve-indoor-parte-1">
            <div className="mb-20">
              <h6 className="title-upper-red">DETALLES</h6>
              <p className="data"><span className="title-small-blue">Actividad:</span> Indoor Cycling ({props.step1Data.activity.name})</p>
              <p className="data"><span className="title-small-blue">Fecha:</span> {props.step1Data.date}</p>
              <p className="data"><span className="title-small-blue">Horario:</span> {props.step1Data.time.name}</p>
            </div>
            <div className="mb-20">
              <p className="data"><span className="title-small-blue">Responsable de la reserva / Participante 1:</span></p>
              <p className="data">Acción No. {responsible.accion}</p>
              <p className="data">{responsible.name} {responsible.lastName}</p>
              <p className="data">{responsible.cedula} ({responsible.parentezco})</p>
            </div>
          </div>
          <div className="container-reserve-indoor-parte-2">
              <Radio.Group value={players}>
                <div className="container-radio-indoor mb-10">
                  <div className={players === 1 ? 'active-radio-check' : 'active-radio'} onClick={()=>{setPlayer2(null);/*setPlayer3(null);*/setPlayers(1)}}>
                    <Radio value={1}>Solo yo</Radio>
                  </div>
                </div>
              </Radio.Group>
          </div>
          </div>
          {showModal && <ModalError activity='cycling' content={contentError} setShowModal={setShowModal}/>}
        <ReserveInnerFooter back={back} forward={forward} />
        </> 
        : 
        <Redirect to="/" />
      } 
    </>
  )
}
export default withRouter(Step3);
