import React, {useState} from 'react';
import ReserveSuccessModal from '../../Molecules/ReserveSuccessModal/ReserveSuccessModal';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import {withRouter, Redirect} from 'react-router-dom';
import request from '../../../services/request';
import ModalError from '../../Molecules/ModalError/ModalError';
import {useSelector} from 'react-redux';
import {getResponsibleUser} from '../../../services/helpers';

function Step3(props) {
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false);
    const [showModalError, setShowModalError] = useState(false)
    const [contentError, setContentError] = useState('');

    const user = useSelector(state => state.userReducer.userInfo)

    // console.log(' props.step1Data.time.name',  props.step1Data.time.name)
    let responsible = {};
    if (props.step1Data) {
      responsible = getResponsibleUser(props.step2Data.responsible, user)
    }
    
    const forward = async () => {
        setLoading(true);
        const reservationItems = [
            { //este es el responsable
              partnerUser: true, //por ahora todos son true porque son socios todos, false seria si es invitado externo
              email: responsible.email,
              firstName: responsible.name,
              lastName: responsible.lastName,
              uniqueId: props.step2Data.responsible,
              legalId: responsible.cedula,
              // eslint-disable-next-line eqeqeq
              defaulter: responsible.moroso == 0 ? false : true,
              actionNumber: responsible.accion,
              parentezco: responsible.parentezco,
              responsable: true
            }
        ]
        const body = {
          sportId: 3,
          activityId: props.step1Data.activity.id,
          activity: props.step1Data.activity.name,
          roomId: 5,
          room: null,
          scheduleId: props.step1Data.time.id,
          openingTime: props.step1Data.time.name[0],
          dayId: props.step1Data.day.id,
          day: props.step1Data.day.name,
          specificLocation: props.step1Data.time.name[7],
          scheduleBlock: props.step1Data.time.name[2].trim(),
          reservationItems: reservationItems,
        }
        // console.log('body:::::::::', body)
        const response = await request('POST', '/create-reservation', body)
        if (response.ok) {
          setShowModal(true)
        } else {
          setLoading(false);
          const data = await response.json()
          if (data.message) {
            setContentError(data.message);
          } else {
            setContentError('Ha ocurrido un error realizando la reserva')
          }
          setShowModalError(true);
        }
    }
    const back = () => {
        props.history.push('/reservas/otros/responsable')
    }
  
    return (
        <>
            {props.step1Data ? 
                <>
                    <div>
                        <div className="mb-20">
                            <h6 className="title-upper-red mb-5">CONFIRMACIÓN DE RESERVACIÓN:</h6>
                            <p className="no-space"><span className="title-small-blue">Responsable de la reserva / (Participante 1): </span> No. {responsible.accion}, {responsible.name} {responsible.lastName}, C.I. {responsible.cedula}</p>
                            <p className="no-space"><span className="title-small-blue">Fecha de la reserva:</span> {props.step1Data.date}</p>
                            <p className="no-space"><span className="title-small-blue">Hora de la reserva:</span> {props.step1Data.time.name}</p>
                        </div>
                        <div className="container-step-5">
                            <div className="mb-20 container-step-5-parte-1">
                                <h6 className="title-upper-red mb-5">DETALLES:</h6>
                                <p className="no-space"><span className="title-small-blue">Actividad:</span> {props.step1Data.activity.name}</p>
                            </div>
                        </div>
                    </div>
                    <ReserveInnerFooter loadingCreateReserve={loading} lastStep back={back} forward={forward} />
                    {showModalError && <ModalError activity='others' content={contentError} setShowModal={setShowModalError} />}
                    {showModal && <ReserveSuccessModal activity='others' title={`RESERVA DE CLASE DE ${props.step1Data.activity.name.toUpperCase()}`}/>}
                </> 
                : 
                <Redirect to="/" />
            } 
        </>
    )
}
export default withRouter(Step3);
