import React from 'react';
import './ReserveFooter.scss';
import logoDashboard from '../../../assets/logo-vaac-white.svg';
import iconInstagram from '../../../assets/VAAC-foother-instagram-icon.png';
import iconFacebook from '../../../assets/VAAC-foother-facebook-icon.png';
import iconTwitter from '../../../assets/VAAC-foother-twitter-icon.png';
import iconPhone from '../../../assets/VAAC-phone-icon.png';
import iconEmail from '../../../assets/VAAC-mail-icon.png';
import iconLocation from '../../../assets/VAAC-ubicacion-icon.png';
// import iconCandado from '../../../assets/VAAC-foother-candado-icon.png';
// import { Link } from 'react-router-dom';

function ReserveFooter() {
  return (
    <div className='main-reserve-footer'>
      <div className='reserve-footer'>
        <div className='reserve-footer-info'>
          <div className='reserve-footer-main'>
            <div className="reserve-footer-main-data one">
              <img src={iconPhone} alt="phone" /> 
              <p>Atención al socio: +58 212 210.2700 / 212 210.2701</p>
            </div>
            <div className="reserve-footer-main-data two">
              <img src={iconEmail} alt="email" />
              <p>atencionalsocio@vaac.com.ve</p>
            </div>
            <div className="reserve-footer-main-data three">
              <img src={iconLocation} alt="location" /> 
              <p>Av. Principal Colinas de Valle Arriba, Segundo Retorno, Valle Arriba Athletic Club, Caracas, Venezuela</p>
            </div>
          </div>
          <div className='reserve-footer-logo'>
            <img src={logoDashboard} alt="Logo VAAC" />
          </div>
          <div className='reserve-footer-cta'>
            {/* <Link to="https://vaac.dyndns.org/user/auth/login#no-back-button" target="_blank" rel="noopener noreferrer" className="button white outline"> <img src={iconCandado} alt="candado" /> Acceso de Socios</Link> */}
            {/* <a rel="noopener noreferrer" href="https://vaac.dyndns.org/user/auth/login#no-back-button" target="_blank"  className="button white outline"> <img src={iconCandado} alt="candado" />Acceso de Socios</a> */}
          </div>
        </div>
        <div className='reserve-footer-social'>
          <div><img src={iconInstagram} alt="iconInstagram" /></div>
          <div><img src={iconFacebook} alt="iconFacebook" /></div>
          <div><img src={iconTwitter} alt="iconTwitter" /></div>
        </div>
        <div className='reserve-footer-copy'> 
          <p className='center-text'>&copy; 2020 VAAC.COM.VE | Todos los derechos reservados | Powered by Ninweb.net</p>
        </div>
      </div>
    </div>
  )
}
export default ReserveFooter;
