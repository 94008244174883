import { Button } from 'antd';
import React, {useState} from 'react';
import './ReserveSelectionBox.scss';
import {withRouter} from 'react-router-dom';
import ModalError from '../ModalError/ModalError';
import { getSettings } from '../../../services/getSettings';

function ReserveSelectionBox(props) {
    const [loadingButton, setLoadingButton] = useState(false)
    const [showModalBloqued, setShowModalBloqued] = useState(false)

    // Antes de ir a la reserva consulto primero si esta bloqueado o no
    const goReserve = async () => {
      setLoadingButton(true)
      getSettings()
      .then(data => {
        if(data.setting.blockReservations) {
          setShowModalBloqued(true)
          setLoadingButton(false)
        } else {
          setShowModalBloqued(false)
          return props.history.push(props.url)
        }
      })
      .catch(() => {
        return
      }) 
    }

    return (
        <div className='reserve-selection-box'>
            <img src={props.icon} alt="icono" className="reserve-selection-box--img"/>
            <p className='reserve-selection-box--name'>{props.name}</p>
            {props.match.params.status === 'activas' && <Button className="button purple outline" onClick={()=>props.history.push(props.url)}>Ver reservas</Button>}
            {props.match.params.status !== 'activas' && <Button className="button purple outline" loading={loadingButton} onClick={()=> goReserve()}>Reservar</Button>}
            {showModalBloqued && <ModalError bloquedReserves content="EN ESTOS MOMENTOS LAS RESERVAS NO SE ENCUENTAN HABILITADAS POR MOTIVO A SEMANA RADICAL" setShowModal={setShowModalBloqued}/>}
        </div>
    )
}
export default withRouter(ReserveSelectionBox);
