import React, {useState} from 'react';
import ReserveSuccessModal from '../../Molecules/ReserveSuccessModal/ReserveSuccessModal';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import {withRouter, Redirect} from 'react-router-dom';
import './Step4.scss';
import request from '../../../services/request';
import ModalError from '../../Molecules/ModalError/ModalError';
import {useSelector} from 'react-redux';
import {getResponsibleUser} from '../../../services/helpers';

function Step4(props) {
  const [showModal, setShowModal] = useState(false)
  const [showModalError, setShowModalError] = useState(false)
  const [loading, setLoading] = useState(false);
  const [contentError, setContentError] = useState('');

  const user = useSelector(state => state.userReducer.userInfo)

  let responsible = {};
  if (props.step1Data) {
    responsible = getResponsibleUser(props.step2Data.responsible, user)
  }
  

  const forward = async () => {
    setLoading(true);
    const reservationItems = []
    reservationItems.push({ //este es el responsable
      partnerUser: true, //por ahora todos son true porque son socios todos, false seria si es invitado externo
      email: responsible.email,
      firstName: responsible.name,
      lastName: responsible.lastName,
      uniqueId: props.step2Data.responsible,
      legalId: responsible.cedula,
      actionNumber: responsible.accion,
      parentezco: responsible.parentezco,
      // eslint-disable-next-line eqeqeq
      defaulter: responsible.moroso == 0 ? false : true,
      responsable: true, //este es el registro del usuario responsable, los demás de abajo son false
    })
    reservationItems.push({ //este es el invitado 1
      partnerUser: true, //por ahora todos son true porque son socios todos, false seria si es invitado externo
      responsable: false,
      ...props.step3Data.player2
    })
    if (props.step3Data.players === 4) {
      reservationItems.push({ //este es el invitado 2
        partnerUser: true, //por ahora todos son true porque son socios todos, false seria si es invitado externo
        responsable: false,
        ...props.step3Data.player3
      })
      reservationItems.push({ //este es el invitado 3
        partnerUser: true, //por ahora todos son true porque son socios todos, false seria si es invitado externo
        responsable: false,
        ...props.step3Data.player4
      })
    }
    const response = await request('POST', '/create-reservation', {
      sportId: 1,
      activityId: props.step3Data.activity.id,
      activity: props.step3Data.activity.name,
      roomId: props.step1Data.field.id,
      room: props.step1Data.field.name,
      scheduleId: props.step1Data.time.id,
      openingTime: props.step1Data.time.name[0],
      scheduleBlock: props.step1Data.time.name[2].trim(),
      dayId: props.step1Data.day.id,
      day: props.step1Data.day.name,
      reservationItems: reservationItems,
    })
    if (response.ok) {
      setShowModal(true)
    } else {
      setLoading(false);
      const data = await response.json()
      if (data.message) {
        setContentError(data.message);
      } else {
        setContentError('Ha ocurrido un error realizando la reserva')
      }
      setShowModalError(true);
    }
  }

  const back = () => {
    props.history.push('/reservas/tenis/invitados')
  }

  return (
    <>
      {props.step1Data 
        ? 
        <>
          <div>
            <div className="body-step-4">
              <div className="body-step-4-parte-1">
                <h6 className="title-upper-red">CONFIRMACIÓN DE RESERVACIÓN</h6>
                <p className="data"><span className="title-small-blue">Responsable de la reserva / Jugador 1:</span> No. {responsible.accion}, {responsible.name} {responsible.lastName}, C.I. {responsible.cedula}</p>
                <p className="data"><span className="title-small-blue">Fecha de la reserva:</span> {props.step1Data.date}</p>
                <p className="data"><span className="title-small-blue">Hora de la reserva:</span> {props.step1Data.time.name}</p>
              </div>

              <div className="body-step-4-parte-2">
                <div className="mt-20 data-1-step-4" >
                  <h6 className="title-upper-red">DETALLES</h6>
                  <p className="data"><span className="title-small-blue">Cancha:</span> {props.step1Data.field.name}</p>
                  <p className="data"><span className="title-small-blue">No. de jugadores:</span> {props.step3Data.players}</p>
                </div>
                <div className="mt-20 mb-20 data-2-step-4">
                  <h6 className="title-upper-red">JUGADORES O INVITADOS</h6>
                  <p className="data"><span className="title-small-blue">Jugador 2:</span> {props.step3Data.player2.firstName} {props.step3Data.player2.lastName} {props.step3Data.player2.legalId}</p>
                  {props.step3Data.players === 4 &&
                    <>
                      <p className="data"><span className="title-small-blue">Jugador 3:</span> {props.step3Data.player3.firstName} {props.step3Data.player3.lastName} {props.step3Data.player3.legalId}</p>
                      <p className="data"><span className="title-small-blue">Jugador 4:</span> {props.step3Data.player4.firstName} {props.step3Data.player4.lastName} {props.step3Data.player4.legalId}</p>
                    </>
                  }
                </div>
              </div>
              <ReserveInnerFooter loadingCreateReserve={loading} lastStep back={back} forward={forward} />
            </div>
          </div>
          {showModalError && <ModalError activity='tenis' content={contentError} setShowModal={setShowModalError} />}
          {showModal && <ReserveSuccessModal activity='tenis' title='RESERVA DE CANCHAS DE TENIS' handleCancel={() => setShowModal(false)} />}
        </> 
          : 
          <Redirect to="/" />
      } 
    </>
  )
}
export default withRouter(Step4);
