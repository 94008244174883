import request from "./request";

export const getReservations = async ({ action, sportId, status, limit = 10, page = 0 }) => {
  try {
    const response = await request( "GET", `/reservations/${action}/${sportId}/null/null/${status}/${limit}/${limit * page}`);
    if (response.status === 200) {
      const data = await response.json();
      console.log("data.reservations", data.reservations);
      return data.reservations
    }
  }catch (error) {
    throw Error('Error al obtener las reservaciones')
  }
};
