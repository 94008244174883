import React from 'react';
import { BrowserRouter as Router, Route, Switch  } from 'react-router-dom';
import PrivateRoute from './components/Atoms/PrivateRoute/PrivateRoute';
// import ReserveLogin from './components/Pages/ReserveLogin/ReserveLogin';
import ReserveHome from './components/Pages/ReserveHome/ReserveHome';
import ReserveTenis from './components/Pages/ReserveTenis/ReserveTenis';
import ReserveSquash from './components/Pages/ReserveSquash/ReserveSquash';
import ReserveIndoorCycling from './components/Pages/ReserveIndoorCycling/ReserveIndoorCycling';
import DetailsReserve from './components/Pages/DetailsReserve/DetailsReserve';
import ProfilePartner from './components/Pages/ProfilePartner/ProfilePartner';
import ReportProblem from './components/Pages/ReportProblem/ReportProblem';
import ReserveList from './components/Pages/ReserveList/ReserveList';
import ReserveOthers from './components/Pages/ReserveOthers/ReserveOthers';
import Login from './components/Pages/Login/Login';
import NotFound from './components/Pages/NotFound/NotFound';

function App() {

  return (
    <>
      <Router>
        <Switch>
          <Route path='/reservas/login' exact component={Login} />
          <PrivateRoute path={['/', '/login/:token']} exact component={ReserveHome} />
          <PrivateRoute path='/datos/socios' exact component={ProfilePartner} />
          <PrivateRoute path={['/reservas/tenis/dia', '/reservas/tenis/responsable', '/reservas/tenis/invitados', '/reservas/tenis/confirmacion']} exact component={ReserveTenis} />
          <PrivateRoute path={['/reservas/squash/dia', '/reservas/squash/responsable', '/reservas/squash/invitados', '/reservas/squash/confirmacion']} exact component={ReserveSquash} />
          <PrivateRoute path={['/reservas/indoor-cycling/dia', '/reservas/indoor-cycling/responsable', '/reservas/indoor-cycling/invitados', '/reservas/indoor-cycling/seleccion-bicicletas', '/reservas/indoor-cycling/confirmación']} exact component={ReserveIndoorCycling} />
          <PrivateRoute path={['/reservas/otros/dia', '/reservas/otros/responsable', '/reservas/otros/confirmacion']} exact component={ReserveOthers} />
          <PrivateRoute path='/reservas/lista' exact component={ReserveList} />
          <PrivateRoute path='/reservas/detalle/:id' exact component={DetailsReserve} />
          <PrivateRoute path='/reservas/reportar-problema' exact component={ReportProblem} />
          <Route component={NotFound}/>
        </Switch>
      </Router>
    </>
  );
}

export default App;
