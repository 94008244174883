import React from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
// import useNotifications from '../../../customHooks/useNotifications';
import { saveNotifications } from '../../../redux/actionsCreators';
import request from '../../../services/request'
import './ItemNotification.scss'
const { DateTime } = require("luxon");

const ItemNotification = ({ notification, setVisibleDrawer }) => {
  // const { setReloadNotifications } = useNotifications();
  const dispatch = useDispatch()

  // Leer una notificacion
  const readNotification = async ({idNotification, notificationRead}) => {
    setVisibleDrawer(false)
    if(notificationRead) return
    await request('PATCH',  `/notification/${idNotification}`).then(res => {
      dispatch(saveNotifications({page: 0}))
      console.log('res PATCH', res)
    })
    // setReloadNotifications(true)
  }

  // Borrar una notificacion
  // const  deleteNotification = ({notificationId}) => {
  //   setVisibleDrawer(true)
  //   console.log('notificationId', notificationId)
  // }


  return (
    <Link to={`/reservas/detalle/${notification.reservationId}`}>
    <div className={`item-notification ${!notification.read && 'unread' }`} onClick={() => readNotification({idNotification: notification.id, notificationRead: notification.read})}>
        <div className="item-notification--img">
          <img src={notification.pictureUrl} alt="icon" />
        </div>

      <div className="item-notification--content"> 
        <div>
          <Link to={`/reservas/detalle/${notification.reservationId}`}><p className="item-notification--message">{notification.info}</p> </Link>
          <Link to={`/reservas/detalle/${notification.reservationId}`}><span className="item-notification--date">{DateTime.now(notification.createdAt).setLocale('es').toFormat('MMMM dd, yyyy')}</span></Link>  
        </div>
      </div>
      {/* <div className="button-delete-notification" onClick={() => deleteNotification({notificationId: notification.id})}>x</div> */}
    </div>
    </Link>
  )
}

export default ItemNotification
