import React, { useState } from "react";
import "./ReserveHeader.scss";
// import { Button } from "antd";
import IconBell from "../../Atoms/IconBell/IconBell";
import logoDashboard from "../../../assets/logo-vaac-white.svg";
import ToggleMenu from "../../Atoms/ToggleMenu/ToggleMenu";
import { Link } from "react-router-dom";
import DrawerNotification from "../DrawerNotification/DrawerNotification";
import { useDispatch } from "react-redux";
import { saveNotifications } from "../../../redux/actionsCreators";

const iOS = navigator.userAgent.includes("Mac");

function ReserveHeader() {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState(null);

  const dispatch = useDispatch();

  const onClose = () => {
    setVisibleDrawer(false);
  };

  if (!iOS) {
    const channel = new BroadcastChannel("sw-messages");
    channel.onmessage = function () {
      dispatch(saveNotifications({ page: 0 }));
      channel.close();
      console.log("saveNotifications");
    };
  } else {
    console.log("Estas en IOS");
  }

  return (
    <>
      <div className="main-reserve-header">
        <div className="reserve-header">
          <div className="reserve-header--logo">
            <Link to="/datos/socios">
              <img src={logoDashboard} alt="Logo VAAC" />
            </Link>
          </div>
          <div className="reserve-header--portal">
            <IconBell
              setVisibleDrawer={setVisibleDrawer}
              notificationsCount={notificationsCount}
            />
            {/* <a href="https://vaac.com.ve/">
              <div className="button-portal">
                <Button className="button turquoise outline ">
                  Ir al Portal de Socios
                </Button>
              </div>
            </a> */}
            <ToggleMenu />
          </div>
        </div>
      </div>
      {visibleDrawer && (
        <DrawerNotification
          visible={visibleDrawer}
          onClose={onClose}
          setNotificationsCount={setNotificationsCount}
          setVisibleDrawer={setVisibleDrawer}
          // loaderDrawer={loaderDrawer}
        />
      )}
    </>
  );
}
export default ReserveHeader;
