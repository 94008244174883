import React, {useState, useEffect} from 'react';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveSuccessModal from '../../Molecules/ReserveSuccessModal/ReserveSuccessModal';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import request from '../../../services/request';
import './DetailsReserve.scss';
import ModalError from '../../Molecules/ModalError/ModalError';
import Spinner from '../../Atoms/Spinner/Spinner';

const DetailsReserve = (props) => { 
  const [cancelReserveReason, setCancelReserveReason] = useState(false);
  const [sucessCancelReserveReason, setSucessCancelReserveReason] = useState(false);
  const [errorCancelReserveReason, setErrorCancelReserveReason] = useState(false);
  const [errorReserve404, setErrorReserve404] = useState(false);
  const [messageReserveReason, setMessageReserveReason] = useState('');
  const [buttonLoadingCancelReason, setButtonLoadingCancelReason] = useState(false);
  const [details, setDetails] = useState(null);
  const [responsable, setResponsable] = useState(null)
  const [sportIcon, setSportIcon] = useState('');

  const back = () => props.history.goBack()

  // Función para cancelar
  const cancelReserve = async ({reason}) => {
    setButtonLoadingCancelReason(true);
    const body = {
      cancellationReason: reason
    }
    const response = await request('PATCH', `/cancel-reservation/${props.match.params.id}`, body);
    const data = await response.json()
    if (response.ok) {
      setButtonLoadingCancelReason(false)
      setSucessCancelReserveReason(true)
      setErrorCancelReserveReason(false)
      setMessageReserveReason(data.message)
    } else {
      setButtonLoadingCancelReason(false)
      setMessageReserveReason(data.message)
      setErrorCancelReserveReason(true)
      if (response.status === 404) {
        setErrorCancelReserveReason(true)
        setMessageReserveReason(data.message)
        //error que no se encontró esa reserva
      } else {
        setErrorCancelReserveReason(true)
        setMessageReserveReason(data.message)
        //error generico
      }
    }
  }

  useEffect(() => {
    // setLoadingReservation(true)
    const reservationRequest = async () => {
      const response = await request('GET', `/reservation/${props.match.params.id}`);
      if (response.status === 200) {
          setErrorReserve404(false)
          const data = await response.json();
          if (data.reservation) {
            setResponsable(data.reservation.reservationItem.filter(rsv => rsv.responsable))
            setDetails(data.reservation)
            if(data.reservation.sport.name === 'Tenis') {
              setSportIcon('tenis')
            } else if(data.reservation.sport.name === 'Indoor Cycling') {
              setSportIcon('cycling')
            }else if(data.reservation.sport.name === 'Squash') {
              setSportIcon('squash')
            } else {
              setSportIcon('others')
            }
          }
        //  setLoadingReservation(false)
      }else if(response.status === 404) {
        setErrorReserve404(true)
      } else {
        console.log('error 500')
      }
    }
    reservationRequest()
  }, [props.match.params.id])

  return (
    <ReserveWrapper centerX>
      <ReserveMainTitle title='Reservas' breadcrumb={['Inicio > ', 'Detalle > ', props.match.params.id]}/>
      <div className="box mb-20">
      { 
        errorReserve404 ? <h6 className="title-upper-red mb-5 flex center max-height"><span>RESERVACIÓN NO ENCONTRADA</span></h6>
        :
        details && responsable && responsable.length > 0 ?
      <div>
        <ReserveInnerTitle activity={sportIcon} title={`RESERVA DE ${details.sport.name}`} subtitle={`N° de reserva principal: ${details.id}`}/>
          <div className="main-detail-reserve">
            <div className="detail-reserve-row-1">
              <h6 className="title-upper-red mb-5">INFORMACIÓN DE RESERVACIÓN:</h6>
              <p className="no-space"><span className="title-small-blue">Responsable de la reserva: </span>{responsable[0].firstName} {responsable[0].lastName} N° de reserva {responsable[0].id}</p>
              {/* <p className="no-space"><span className="title-small-blue">Fecha de la reserva: </span>{new Date(details.reservationDate).toLocaleDateString()}</p> */}
              <p className="no-space"><span className="title-small-blue">Fecha de la reserva: </span>{details.reservationDate}</p>
              <p className="no-space"><span className="title-small-blue">Hora de la reserva: </span> {details.schedule.openingTime} - {details.schedule.closingTime} {details.schedule.scheduleBlock}</p>
            </div>
            <div className="detail-reserve-row-2 mt-20">
              <div className="detail-reserve-row-2--datails" >
                <h6 className="title-upper-red">DETALLES:</h6>
                {details.status === process.env.REACT_APP_CANCELLED_RESERVATION &&  <p className="no-space"><span className="title-small-blue">Estatus de la reserva: </span> {details.status}</p> }
                {details.status === process.env.REACT_APP_CANCELLED_RESERVATION && details.cancellationReason && <p className="no-space"><span className="title-small-blue">Razón de la cancelación: </span> {details.cancellationReason}</p> }
                {details.status === process.env.REACT_APP_USED_RESERVATION &&  <p className="no-space"><span className="title-small-blue">Estatus de la reserva: </span> Calificada</p> }
                <p className="no-space"><span className="title-small-blue">Actividad:</span> {details.sport ? details.sport.name : 'N/A'} ({details.activity ? details.activity.name : 'N/A'})</p>
                <p className="no-space"><span className="title-small-blue">Sala:</span> {details.room ? details.room.name : 'N/A'}</p>
                {details.specificLocation && <p className="no-space"><span className="title-small-blue">Espacio:</span> {details.specificLocation}</p>}
                <p className="no-space"><span className="title-small-blue">No. de participantes:</span> {details.reservationItem.length}</p>
                {responsable[0].reservationPlaceId && <p className="no-space"><span className="title-small-blue">Bicicleta seleccionada:</span> {responsable[0].reservationPlaceId} </p>}
              </div>
              
              {details.reservationItem.length > 1 && 
                <div className="detail-reserve-row-2--participants">
                  <h6 className="title-upper-red">INVITADOS:</h6>
                  {
                    details.reservationItem.filter(item => !item.responsable).map((socio, index) => 
                      <p key={index} className="no-space"><span className="title-small-blue">Invitado {index + 1}:</span> {socio.firstName} {socio.lastName}, N° de reserva {socio.id}</p>
                    )
                  }
                </div>
              }
            </div>
          </div>
        <ReserveInnerFooter setCancelReserveReason={setCancelReserveReason} back={back} statusReserve={details.status} alreadyPassed={details.alreadyPassed} detailsReserve cancelReserve={cancelReserve}/>
      </div> : <Spinner small/> 
      }
      {/* {showModal && <ReserveSuccessModal activity='tenis' title='RESERVA DE CANCHAS DE TENIS' message='Por favor confirma si desea desactivar ésta sala' setShowModal={setShowModal} deleteReserve={cancelReserve}/>} */}
      {cancelReserveReason && <ReserveSuccessModal cancelReserveReasonSocio buttonLoadingCancelReason={buttonLoadingCancelReason} title='CANCELAR RESERVA' message={`Usted está por cancelar esta reserva, ¿Estás seguro?`}  setCancelReserveReason={setCancelReserveReason}  cancelReserve={cancelReserve}/>}
      {sucessCancelReserveReason && <ModalError deleteReserveReason content={messageReserveReason} setShowModal={setSucessCancelReserveReason}/>}
      {errorCancelReserveReason && <ModalError content={messageReserveReason} setShowModal={setErrorCancelReserveReason}/>}
      </div>
    </ReserveWrapper>
  )
}
export default DetailsReserve;
