import React, {useState} from 'react';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import { Radio} from 'antd';
import {withRouter, Redirect} from 'react-router-dom';
import './Step3.scss';
import ModalError from '../../Molecules/ModalError/ModalError';
import ActionInput from '../../Atoms/ActionInput/ActionInput';
import {useSelector} from 'react-redux';
import {getResponsibleUser} from '../../../services/helpers';

function Step3(props) {
  const [activity, setActivity] = useState(props.step3Data ? props.step3Data.activity : {id: 1, name: 'SINGLE'});
  const [players, setPlayers] = useState(props.step3Data ? props.step3Data.players : 2);
  const [player2, setPlayer2] = useState(props.step3Data ? props.step3Data.player2 : null);
  const [player3, setPlayer3] = useState(props.step3Data ? props.step3Data.player3 : null);
  const [player4, setPlayer4] = useState(props.step3Data ? props.step3Data.player4 : null);
  const [contentError, setContentError] = useState('');
  const [showModal, setShowModal] = useState(false);

  const user = useSelector(state => state.userReducer.userInfo)

  const forward = () => {
      //validar que no está buscando a si mismo, es decir, que el logeado se busca a si mismo en los invitados
      if (players === 2 && !player2) {
        setContentError('Debe definir el jugador 2');
        setShowModal(true);
      } else if (players === 4 && (!player2 || !player3 || !player4)) {
        setContentError('Debe definir todos los jugadores');
        setShowModal(true);
      } else {
          setShowModal(false);
          props.saveData({
              players, player2, player3, player4, activity
          })
          props.history.push('/reservas/squash/confirmacion')
      }
  }
  const back = () => {
      props.history.push('/reservas/squash/responsable')
  }

  let responsible = {};
  if (props.step1Data) {
    responsible = getResponsibleUser(props.step2Data.responsible, user)
  }

  return (
    <>
      {props.step1Data ? 
      <>
       <div>     
        <div className="body-step-3">
          <div className="body-step-3-parte-1">
            <div>
              <h4 className="title-upper-red">DETALLES</h4>
              <p className="data"><span className="title-small-blue">Cancha: </span> {props.step1Data.field.name}</p>
              <p className="data"><span className="title-small-blue">Fecha: </span> {props.step1Data.date}</p>
              <p className="data"><span className="title-small-blue">Horario: </span> {props.step1Data.time.name}</p>
              <div className="mt-20">
                <p className="data"><span className="title-small-blue">Responsable de la reserva / Jugador 1</span></p>
                <p className="data">Acción No. {responsible.accion}</p>
                <p className="data">{responsible.name} {responsible.lastName}</p>
                <p className="data">{responsible.cedula} ({responsible.parentezco})</p>
              </div>
            </div>
          </div>
          <div className="body-step-3-parte-2">
            <Radio.Group className="main-container-input-radio" value={players}>
              <div className="container-input-radio">
                <div className={players === 2 ? 'active-radio-check' : 'active-radio'} onClick={()=>{ setPlayer3(null); setPlayer4(null); setPlayers(2); setActivity({id: 1, name: 'SINGLE'}) }}>
                    <Radio value={2}>SINGLE</Radio>
                </div>
                {/* <div className={players === 4 ? 'active-radio-check' : 'active-radio'} onClick={()=>{setPlayers(4); setActivity({id: 2, name: 'DOUBLE'})}}>
                    <Radio value={4}>DOUBLE</Radio>
                </div> */}
              </div>
            </Radio.Group>
            <div className="main-container-input-reserve">
              <div className="container-input-reserve">
                <p className="title-small-blue mb-5">Jugador Adicional 02:</p>
                <ActionInput responsable={responsible} setErrorMessage={err => setContentError(err)} showError={() => setShowModal(true)} activity='tenis' onChange={val=>setPlayer2(val)} defaultValue={player2} />
              </div>
              {/* {
                players === 4 && 
                <>
                  <div className="container-input-reserve">
                    <p className="title-small-blue mb-5">Jugador Adicional 03:</p>
                    <ActionInput setErrorMessage={err => setContentError(err)} showError={() => setShowModal(true)} activity='tenis' onChange={val=>setPlayer3(val)} defaultValue={player3} />
                  </div>
                  <div className="container-input-reserve">
                    <p className="title-small-blue mb-5">Jugador Adicional 04:</p>
                    <ActionInput setErrorMessage={err => setContentError(err)} showError={() => setShowModal(true)} activity='tenis' onChange={val=>setPlayer4(val)} defaultValue={player4} />
                  </div>
                </>
              } */}
            </div>
          </div>
        </div>       
          <ReserveInnerFooter back={back} forward={forward} />
        </div>
        {showModal && <ModalError activity='squash' content={contentError} setShowModal={setShowModal}/>}
      </> 
      : 
      <Redirect to="/" />
      } 
    </>
  )
}
export default withRouter(Step3);
