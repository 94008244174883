import React from 'react';
import {Modal, Button, Input, Form} from 'antd';
import {withRouter} from 'react-router-dom';
import './ReserveSuccessModal.scss' ;
import logo from '../../../assets/logo-vaac-blue.svg';
import iconTenis from '../../../assets/icon-tenis.svg';
import iconCycling from '../../../assets/icon-cycling.svg';
import iconOthers from '../../../assets/icon-others.svg';
import iconSquash from '../../../assets/icon-squash.svg';
import { Link } from 'react-router-dom';

const icons = {
	'tenis': iconTenis,
	'cycling': iconCycling,
	'squash': iconSquash,
	'others': iconOthers
}

function ReserveSuccessModal(props) {

  const changeFinish = (e) => {
    props.cancelReserve(e)
  }
  return (
    <>
     <div className="modal-reserves">
      <Modal footer={null} visible={true} centered closable={false}>
        <div className="modal-body-reserves">
          <div className="main-modal-body-reserves--logo"><img src={logo} alt="Logo Vaac" className="modal-body-reserves--logo"/></div>
          {props.activity && <div><img src={icons[props.activity]} alt="Icono" className="modal-body-reserves--icon"/></div>}
          <h6 className={`title-upper-blue ${props.report || props.cancelReserveReasonSocio || props.session ? 'mt-20' : ''}`}>{props.delete ? 'CANCELACIÓN DE RESERVA' : props.title}</h6>
          <p className="modal-body-reserves-message">
            {
              props.session ? props.sessionMessage
              :
              props.cancelReserveReasonSocio ? null :
              props.delete 
              ? `Usted está por cancelar la reversa No. ${props.reserveID} por favor confirme esta acción.` 
              :
              props.report 
              ? props.reportMessage
              : 'Operación existosa, por favor revise su correo electrónico para más detalles.'
            }
          </p>
          {
            props.cancelReserveReasonSocio || props.session? null :
            props.delete ?
            <Button className="button purple mb-10" onClick={() => props.cancelReserve()}>SI CANCELAR ESTA RESERVA</Button>
            :
            props.report
            ? <div className="mb-15 mt-15"><Link to='/reservas/lista' className="modal-body-reserves-link">IR A MIS RESERVAS</Link></div>
            : <Button className="button purple mb-10" onClick={()=>props.history.push('/reservas/lista')}>IR A MIS RESERVAS ACTIVAS</Button>
          }
          {
            props.cancelReserveReasonSocio || props.session ? null :
            props.delete ? 
            <div><Link className="modal-body-reserves-link" to="#" onClick={() => props.setShowModal(false)}>No deseo cancelarla.</Link></div>
            : 
            props.report
            ? null
            :<div><Link className="modal-body-reserves-link" to='/'>O reservar otra actividad</Link></div>
          }
          {
            props.session &&
            <div className="buttons-modal-session">
              <div><p className="modal-body-reserves-link mb-5 ml-10 mr-10" to="#" onClick={() => props.history.push('/datos/socios')}>Si, ingresar</p></div>
              <div><p className="modal-body-reserves-link mb-5 " onClick={()=>props.cancelInitSession()} >No, no deseo ingresar.</p></div>
            </div>
          }
          {/* Cancelar reseva */}
          {
            props.cancelReserveReasonSocio &&  // Para cancelar la clase / relacion
            <>
            <p className="modal-body-reserves-message">{props.message}</p>
            <Form onFinish={changeFinish}>
              <div>
                <p className='title-small-blue mb-5'>Indique la razón: </p>
                <Form.Item name="reason" placeholder="Ingrese la razón" rules={[{required: true, message: 'Campo obligatorio' }]}>
                  <Input className="input" />
                </Form.Item>
              </div>
              <Button className="button purple mb-10 ml-5 mr-5" htmlType="submit" loading={props.buttonLoadingCancelReason} >SÍ, CANCELAR</Button>
            </Form>
              
            <div><p className="modal-body-reserves-link" href="#" onClick={() => props.setCancelReserveReason(false)} type='link' >No, no deseo cancelarla.</p></div>
            </>
          } 
        </div>
      </Modal>
     </div>     
    </>
  )
}
export default withRouter(ReserveSuccessModal);
