import React, { useState } from 'react'
import './Login.scss';
import { Form, Input, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import logoLogin from '../../../assets/logo-vaac-blue.svg'
import ReserveFooter from '../../Molecules/ReserveFooter/ReserveFooter';
import { messageCatchRequest } from '../../../services/helpers';
import request from '../../../services/request';
import ReserveSuccessModal from '../../Molecules/ReserveSuccessModal/ReserveSuccessModal';

const Login = (props) => {

  const [buttonLoading, setButtonLoading] = useState(false);
  const [sessionModal, setSessionModal] = useState(false);

  const [errorLogin, setErrorLogin] = useState(false);
  const [errorLoginMessage, setErrorLoginMessage] = useState('');

  const onFinish = async (e) => {
    const url = `https://vaac.dyndns.org/tisolucionesapi/socios/login`
      setButtonLoading(true);
      const formData = new FormData()
      formData.append("username", e.email.trim().toLowerCase())
      formData.append("password",  e.password.trim())
      const settings = {
        method: 'POST',
        body : formData
      }
      try {
        const response = await fetch(url, settings);
        const data = await response.json();
        console.log('data desde login', data)
        if(data.access_token) {
          localStorage.setItem('token', data.access_token)
          fetch("https://vaac.dyndns.org/tisolucionesapi/socios/datosocio", {
            headers: {
              'Authorization': `Bearer ${data.access_token}`
            }
          })
          .then(res=>res.json())
          .then(async res => {
          
            const body = {
              actionNumber : res.ACCION
            }
            const response = await request('POST', '/check-connection', body)
            const data = await response.json()
            console.log('response', response)
            console.log('data', data)
            console.log('data desde tisolucionesapi', res)
            if(response.status === 200) {
              props.history.push('/datos/socios');
              setErrorLogin(false)
              setButtonLoading(false);
            } else if(response.status === 403) {
                setSessionModal(true)
            } else {
              setErrorLoginMessage(messageCatchRequest)
              setButtonLoading(false);
              setErrorLogin(true)
            }
          })
        } else {
          setErrorLoginMessage(data.descripcion)
          setButtonLoading(false);
          setErrorLogin(true)
        }
      } catch (error) {
        setErrorLoginMessage(messageCatchRequest)
        setButtonLoading(false);
        setErrorLogin(true)
      }
  }

  const cancelInitSession = () => {
    setButtonLoading(false);
    setSessionModal(false)
  }
  return (
    <div className="reserve-login-admin">
      <div className="background-vaac">
        <div className="capa"></div>
      </div>
      <div className="capa-gris"></div>
      <header  className="reserve-login-admin--header">
        <img src={logoLogin} alt="Logo Vaac"/>
      </header>
      <div className="reserve-login-admin--form">
        <div>
          <h3 className="reserve-login-admin--title mb-20">Sistema de reservas</h3>
          {/* <p className="reserve-login-admin--subtitle">Por favor, complete los siguientes campos para iniciar sesión:</p> */}
          <Form onFinish={(e) => onFinish(e)} >
            { errorLogin && <p className="mb-15 message-error center-text">{errorLoginMessage}</p> }
            <Form.Item label="Nombre de usuario:" name="email" rules={[{required: true, message: 'Campo obligatorio' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Contraseña:" name="password" rules={[{ required: true, message: 'Campo obligatorio' }]}>
              <Input.Password />
            </Form.Item>

            <Form.Item className="mt-15">
              <Button htmlType="submit" icon={<LockOutlined />} loading={buttonLoading} className="button blue center-btn btn-login">
                Ingresar
              </Button>
            </Form.Item>

            <div className="reserve-login-admin--links">
              <a href="https://vaac.dyndns.org/user/recovery/recover-password#no-back-button" target="_blank" rel="noopener noreferrer" className="mb-5 link">¿Olvidó su contraseña?, click aquí</a>
            </div>
          </Form>
        </div>
      </div>
      <div className="reserve-login-admin--footer">
        <ReserveFooter />
      </div>
      {sessionModal && <ReserveSuccessModal session  sessionMessage={`Actualmente tienes una sesión activa. ¿Deseas cerrarla y continuar?`}  cancelInitSession={() => cancelInitSession()} />}
    </div>
  )
}

export default Login;
