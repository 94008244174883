import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { getReservations } from '../services/getReservations'

const INITIAL_PAGE = 0
const useReservations = ({ action, sportId, status }) => {
  const [loadingReservations, setLoadingReservations] = useState(true);
  const [loadingReservationsNext, setLoadingReservationsNext] = useState(false);
	const [reserveList, setReserveList] = useState([])
	const [reserveCount, setReserveCount] = useState(0)
  const [reserveCountRow, setReserveCountRow] = useState(0);
	const [page, setPage] = useState(INITIAL_PAGE)

  useEffect(() => {
		setLoadingReservations(true)
    getReservations({ action, sportId, status}).then(reservations => {
      const newReserves = reservations.rows.map(rsv => ({
        number: rsv.id,
        date: rsv.reservationDate,
        time: `${rsv.schedule.openingTime} - ${rsv.schedule.closingTime} ${rsv.schedule.scheduleBlock}`,
        activity: `${rsv.sport.name} (${rsv.activity.name})`,
        details: <Link to={`/reservas/detalle/${rsv.id}`}>Ver detalle</Link>,
        key: rsv.id
      }))
      setReserveList(newReserves);
      setLoadingReservations(false);
      setReserveCount(reservations.count);
      setReserveCountRow(reservations.rows.length);
    }).catch(error => {
      console.log('Error al obtener las reservas', error)
    })
	}, [action, sportId, status])

  useEffect(() => {
    if(page === INITIAL_PAGE) return

		setLoadingReservationsNext(true)
    getReservations({ action, sportId, status, page}).then(reservations => {
      const newReserves = reservations.rows.map(rsv => ({
        number: rsv.id,
        date: rsv.reservationDate,
        time: `${rsv.schedule.openingTime} - ${rsv.schedule.closingTime} ${rsv.schedule.scheduleBlock}`,
        activity: `${rsv.sport.name} (${rsv.activity.name})`,
        details: <Link to={`/reservas/detalle/${rsv.id}`}>Ver detalle</Link>,
        key: rsv.id
      }))
      setReserveList(prevReservations => prevReservations.concat(newReserves));
      setLoadingReservationsNext(false);
      setReserveCountRow(reservations.rows.length);
    }).catch(error => {
      console.log('Error al obtener las reservas', error)
    })
	}, [action, sportId, status, page])

  return { reserveList, loadingReservations, loadingReservationsNext, reserveCountRow, setPage, reserveCount }

}

export default useReservations
