import React from 'react'
import { BellOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import './IconBell.scss';
import { useSelector } from 'react-redux';

const IconBell = ( { setVisibleDrawer }) => {

  const notificationsCount = useSelector(state => state.userReducer.notificationsCount)

  const handleClickBell = () => {
    setVisibleDrawer(true)
  }

  return (
    <>
     <Badge className="count-notification" count={notificationsCount > 9 ? `${9}+` :notificationsCount} gap={1}>
      <div className="main-icon-bell" onClick={() => handleClickBell()}>
          <div className="icon-bell">
            <BellOutlined />
          </div>
          <CaretDownOutlined />
        </div>
    </Badge>
    </>
  )
}

export default IconBell
