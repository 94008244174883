import request from "./request";

export const savePartnerData = async ({uniqueId, actionNumber, parentezco, firstName, lastName}) => {
  try {
    const response = await request('POST', '/save-partner-data', {uniqueId, actionNumber, parentezco, firstName, lastName})
    const data = await response.json()
    if (response.status === 200) {
      return data.token
    } else {
      return null
    }
    } catch (error) {
      return null
    }
};
