// import { Button } from 'antd';
import React from 'react';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import './ProfilePartner.scss';
import socioPhoto from '../../../assets/socio.png'
import {useSelector} from 'react-redux';

function ProfilePartner(props) {
  // const status = props.match.params.status;

    const user = useSelector(state => state.userReducer.userInfo)

    return (
      <ReserveWrapper centerX>
        <ReserveMainTitle title="Mis datos: " breadcrumb={['Inicio > ', 'Acceder a mis datos de socio']}/>
        <div className='box mb-30'>
          <ReserveInnerTitle activity={false} title='DATOS DEL SOCIO' subtitle='A continuación una lista de información de su acción'/>
          <div className='main-profile-partner'>
            <div className="main-profile-partner--parte-1">
              <div className="main-profile-partner--img-container"><img src={socioPhoto} alt="socio" /></div>
              {/* <Button className="button turquoise outline mt-15">Cargar foto</Button> */}
            </div>
            <div className="main-profile-partner--parte-2 mb-25">
              <div className="mb-15">
                <p className="no-space"><span className="title-small-blue">Acción: </span>{user.ACCION}</p>
                <p className="no-space"><span className="title-small-blue">Titular: </span>{user.NOMBRES} {user.APELLIDOS}, C.I. {user.CEDULA}</p>
                {user.CORREO && <p className="no-space"><span className="title-small-blue">Correo: </span>{user.CORREO}</p>}
              </div>
              <div className="mb-15">
                {user.FAMILIARES.map((socio, i) => 
                  <p key={i} className="no-space"><span className="title-small-blue">Familiar {i+1}: </span>{socio.NOMBRES} {socio.APELLIDOS}, CI. {socio.CEDULA}</p>
                )}
              </div>
              <div>
                {/* eslint-disable-next-line eqeqeq */}
                <p className="no-space"><span className="title-small-blue">Solvencia: </span>{user.MOROSIDAD == 0 ? "Se encuentra solvente" : "No se encuentra solvente"}</p>
                {/*<p className="no-space"><span className="title-small-blue">Reservaciones activas: </span>01 (Puede tomar el total de reservas activas)</p>*/}
              </div>
            </div>
          </div>
          <ReserveInnerFooter notBack profile />
        </div>
      </ReserveWrapper>
    )
}
export default ProfilePartner;
