export function getAvailableDays() {
	const days = {
        0: 'Domingo',
        1: 'Lunes',
        2: 'Martes',
        3: 'Miércoles',
        4: 'Jueves',
        5: 'Viernes',
        6: 'Sábado'
    }
	const date = new Date();
    const today = date.getDay();
    const time = date.getHours();
    const availableDays = [days[today]]
    if (time >= 8) {
        availableDays.push(days[today === 6 ? 0 : today + 1])
    }
    return availableDays
}

export function getDate(selectedDay) {
	let date = new Date();
    if (date.getDay() === (selectedDay === 7 ? 0 : selectedDay)) {
        date = date.toLocaleString('es-VE').split(' ')[0];
    } else {
        date.setDate(date.getDate() + 1);
        date = date.toLocaleString('es-VE').split(' ')[0]; 
    }
    return date
}

export function getResponsibleUser(id, userData) {
    if (userData.IDUNICO === id) {
      // console.log('helpers if id', id)
      // console.log('helpers if userData', userData)
      // console.log('userData', userData)
        return {
            name: userData.NOMBRES,
            lastName: userData.APELLIDOS,
            idUnico: userData.IDUNICO,
            accion: userData.ACCION,
            cedula: userData.CEDULA,
            parentezco: userData.PARENTESCO,
            email: userData.CORREO,
            moroso: userData.MOROSIDAD === 1
        }
    } else {
        let responsible = {}
        // console.log('helpers else id', id)
        // console.log('helpers else userData', userData)
        userData.FAMILIARES.forEach(socio => {
            if (socio.IDUNICO === id) {
              // console.log('socio', socio)
                responsible = {
                    name: socio.NOMBRES,
                    lastName: socio.APELLIDOS,
                    // idUnico: userData.IDUNICO,
                    idUnico: id,
                    accion: socio.ACCION,
                    cedula: socio.CEDULA,
                    parentezco: socio.PARENTESCO,
                    email: socio.CORREO,
                    moroso: socio.MOROSIDAD === 1
                }
            }
        })
        return responsible
    }
}


export const getActivityType = ({sportId}) => {
  let result =
        sportId === 4 ? {name: 'Squash', shortname: 'squash', id: 4}
        :
        sportId === 1 ? {name: 'Tenis', shortname: 'tenis', id: 1} 
        : 
        sportId === 3 ? {name: 'Otras Salas', shortname: 'others', id: 3} 
        :
        sportId === 2 && {name: 'Indoor Cycling', shortname: 'cycling', id: 2};
  return result
}

export const messageCatchRequest = "Ha ocurrido un error inesperado, por favor intenta de nuevo o más tarde"
