import React from 'react';
import {Spin} from 'antd';
import './Spinner.scss';

function Spinner({small , small_x2}) {
	return (
    <div className={`spinner ${small && 'small'} ${small_x2 && 'small_2'}`}>
      <Spin size={`${small || small_x2 ? 'middle' : 'large'}`}  />
    </div>
  )
}

export default Spinner;
