import React, { useEffect, useState} from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { saveUser, saveToken, logoutUser, saveZone, saveNotifications }from '../../../redux/actionsCreators';
import { useDispatch} from 'react-redux';
import Spinner from '../Spinner/Spinner';
import { savePartnerData } from '../../../services/savePartnerData';
import { registerPush } from '../../../services/registerPush';

function PrivateRoute({component: Component, ...props }) {
	const [loading, setLoading] = useState(true);
  const dispatch = useDispatch()

	useEffect(() => {
    const getUserData = token => {
      fetch("https://vaac.dyndns.org/tisolucionesapi/socios/datosocio", {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(res=>res.json())
      .then(res=>{
        // console.log('res :::::::::::::::' , res)
        if (res.ACCION) {
          savePartnerData({uniqueId: res.IDUNICO, actionNumber: res.ACCION, parentezco: res.PARENTESCO, firstName: res.NOMBRES, lastName: res.APELLIDOS})
          .then(data => {
            if(data) {
              localStorage.setItem('dashboardToken', data)
              // console.log('data desde private dashboardToken', data)
              fetch("https://vaac.dyndns.org/tisolucionesapi/socios/datosfamiliares", {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              })
              .then(res2=>res2.json())
              .then(res2=>{
                if (res2.FAMILIARES) {
                  //guardo datos del usuario y sus familiares en redux
                  console.log('res', res)
                  registerPush()
                  dispatch(saveUser(Object.assign(res, res2)));
                  dispatch(saveNotifications({page: 0}))
                  dispatch(saveToken(token))
                  dispatch(saveZone())
                  //ver si hacer login interno
                }
                setLoading(false);
              })
            } else {
              dispatch(logoutUser());
              localStorage.clear();
              props.history.push('/reservas/login');
            }
          })
          .catch(() => {
            dispatch(logoutUser());
            localStorage.clear();
            props.history.push('/reservas/login');
          }) 
        } else {
          //si entra aqui es porque token es invalido o ocurrio un error haciendo login, en cualquier caso, redirijo a sitio publico
          setLoading(false);
        }
      })
    }

		const token = props.computedMatch.params.token || localStorage.getItem('token'); //token nuevo tiene preferencia sobre el viejo
		if (props.computedMatch.params.token) {
			localStorage.setItem('token', props.computedMatch.params.token) //guardo el token nuevo que viene
		}
		if (token) {
			//valido token aqui haciendo peticion a tisoluciones
			getUserData(token)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


    return (
      <>
        <Route {...props} render={routeProps => {
          return props.userInfo.IDUNICO 
            ? 
            <Component {...routeProps} /> //si usuario esta logeado, avanza normal  
            :
            (props.computedMatch.params.token || localStorage.getItem('token')) && loading ? //si usuario no esta logeado pero tiene un token, lo valido
              //mientras loading sea true, significa que se está validando el token, se muestra spinner
             <Spinner />
              : //si entra aqui es porque o no tiene token o loading es falso, significa que ya se chequeó token y aun no se tiene userInfo, significa que token es invalido o vencido
            <Redirect to="/reservas/login" />
        }} />
      </>
    )
}

const mapStateToProps = state => ({
    userInfo: state.userReducer.userInfo
})

export default connect(mapStateToProps)(withRouter(PrivateRoute));
