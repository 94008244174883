import React, {useState} from 'react';
import ReserveSuccessModal from '../../Molecules/ReserveSuccessModal/ReserveSuccessModal';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import {withRouter, Redirect} from 'react-router-dom';
import './Step5.scss';
import request from '../../../services/request';
import ModalError from '../../Molecules/ModalError/ModalError';
import {useSelector} from 'react-redux';
import {getResponsibleUser} from '../../../services/helpers';

function Step5(props) {
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false);
    const [showModalError, setShowModalError] = useState(false)
    const [contentError, setContentError] = useState('');

    const user = useSelector(state => state.userReducer.userInfo)

    let responsible = {};
    if (props.step1Data) {
      responsible = getResponsibleUser(props.step2Data.responsible, user)
    }
    
    // console.log('props.step1Data.time.name', props.step1Data.time.name)
    const forward = async () => {
        setLoading(true);
        const reservationItems = []
        reservationItems.push({ //este es el responsable
          partnerUser: true, //por ahora todos son true porque son socios todos, false seria si es invitado externo
          email: responsible.email,
          firstName: responsible.name,
          lastName: responsible.lastName,
          uniqueId: props.step2Data.responsible,
          legalId: responsible.cedula,
          // eslint-disable-next-line eqeqeq
          defaulter: responsible.moroso == 0 ? false : true,
          actionNumber: responsible.accion,
          parentezco: responsible.parentezco,
          responsable: true, //este es el registro del usuario responsable, los demás de abajo son false
          reservationPlaceId: props.step4Data.seats[0] //primera bicicleta
        })
        if (props.step3Data.players === 2) {
            reservationItems.push({ //este es el invitado
                partnerUser: true, //por ahora todos son true porque son socios todos, false seria si es invitado externo
                responsable: false,
                reservationPlaceId: props.step4Data.seats[1], //segunda bicicleta
                ...props.step3Data.player2
            })
        } 
        const response = await request('POST', '/create-reservation', {
          sportId: 2,
          activityId: props.step1Data.activity.id,
          activity: props.step1Data.activity.name,
          roomId: props.step1Data.sala.id,
          room: props.step1Data.sala.name,
          scheduleId: props.step1Data.time.id,
          openingTime: props.step1Data.time.name[0],
          scheduleBlock: props.step1Data.time.name[3].trim(),
          dayId: props.step1Data.day.id,
          day: props.step1Data.day.name,
          reservationItems: reservationItems,
        })
        if (response.ok) {
          setShowModal(true)
        } else {
          setLoading(false);
          const data = await response.json()
          if (data.message) {
            setContentError(data.message);
          } else {
            setContentError('Ha ocurrido un error realizando la reserva')
          }
          setShowModalError(true);
        }
    }

    const back = () => {
        props.history.push('/reservas/indoor-cycling/seleccion-bicicletas')
    }
  
    return (
        <>
            {props.step1Data ? 
                <>
                    <div>
                        <div className="mb-20">
                            <h6 className="title-upper-red mb-5">CONFIRMACIÓN DE RESERVACIÓN:</h6>
                            <p className="no-space"><span className="title-small-blue">Responsable de la reserva / (Participante 1): </span> No. {responsible.accion}, {responsible.name} {responsible.lastName}, C.I. {responsible.cedula}</p>
                            <p className="no-space"><span className="title-small-blue">Fecha de la reserva:</span> {props.step1Data.date}</p>
                            {/* {console.log('props.step1Data.time', props.step1Data.time)} */}
                            <p className="no-space"><span className="title-small-blue">Hora de la reserva:</span> {props.step1Data.time.name}</p>
                        </div>
                        <div className="container-step-5">
                            <div className="mb-20 container-step-5-parte-1">
                                <h6 className="title-upper-red mb-5">DETALLES:</h6>
                                <p className="no-space"><span className="title-small-blue">Actividad:</span> Indoor Cycling ({props.step1Data.activity.name})</p>
                                <p className="no-space"><span className="title-small-blue">Bicicleta(s) reservada(s):</span> {props.step4Data.seats.length === 1 ? props.step4Data.seats[0] : props.step4Data.seats[0] + ' y ' + props.step4Data.seats[1]}</p>
                                <p className="no-space"><span className="title-small-blue">No. de participantes:</span> {props.step3Data.players}</p>
                            </div>
                            <div className="mb-20 container-step-5-parte-2">
                                <h6 className="title-upper-red mb-5">PARTICIPANTES O INVITADOS:</h6>
                                {props.step3Data.players === 2 ?
                                  <p className="no-space"><span className="title-small-blue">Participante 02:</span> {props.step3Data.player2.firstName} {props.step3Data.player2.lastName} {props.step3Data.player2.legalId}</p>
                                  :
                                  <p className="no-space">Ninguno</p>
                                }
                            </div>
                        </div>
                    </div>
                    <ReserveInnerFooter loadingCreateReserve={loading} lastStep back={back} forward={forward} />
                    {showModalError && <ModalError activity='cycling' content={contentError} setShowModal={setShowModalError} />}
                    {showModal && <ReserveSuccessModal activity='cycling' title='RESERVA DE CLASE DE INDOOR CYCLING' handleCancel={() => setShowModal(false)} />}
                </> 
                : 
                <Redirect to="/" />
            } 
        </>
    )
}
export default withRouter(Step5);
