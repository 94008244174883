import React from 'react';
import ReserveFooter from '../../Molecules/ReserveFooter/ReserveFooter';
import ReserveHeader from '../../Molecules/ReserveHeader/ReserveHeader';
import ReserveMenu from '../../Molecules/ReserveMenu/ReserveMenu';
import {withRouter} from 'react-router';
import './ReserveWrapper.scss';
 
function ReserveWrapper(props) {
	return (
		<>
			<ReserveHeader />
			<div className="reserve-body">
				<ReserveMenu {...props} hidden/> {/* hidden para ocultarlo en movil */}
				<div className={'reserve-body-main '+(props.centerX ? 'reserve-body-center-x ':'')+(props.centerY ? 'reserve-body-center-y':'')}>
					<div>
						{props.children}
					</div>
				</div>
			</div>
			<ReserveFooter />
		</>
	)
}

// export default withRouter(ReserveWrapper);
export default withRouter(ReserveWrapper)
