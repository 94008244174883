import { SAVE_USER, SAVE_TOKEN, LOGOUT_USER, SAVE_ZONE, SAVE_NOTIFICATIONS } from "./actions";

const initialUserState = {
  userInfo: {},
  token: null,
};

const zonaHoraria = new Date()

export const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case SAVE_USER:
      return {
        ...state,
        userInfo: Object.assign(state.userInfo, action.data),
      };
    case SAVE_TOKEN:
      return {
        ...state,
        token: action.data,
      };
      case LOGOUT_USER:
      return { 
        ...state,
        userInfo: action.data
      }
      case SAVE_NOTIFICATIONS:
      return { 
        ...state,
        notifications: action.data[0],
        notificationsCount: action.data[1]
      } 
      case SAVE_ZONE:
      return { 
        ...state,
        zona: zonaHoraria
      }
    default:
      return state;
  }
};
