import React, {useState} from 'react';
import { Input, Select } from 'antd';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import './ReportProblem.scss';
import ReserveSuccessModal from '../../Molecules/ReserveSuccessModal/ReserveSuccessModal';
import ModalError from '../../Molecules/ModalError/ModalError';
import {useSelector} from 'react-redux';
import request from '../../../services/request';
const { TextArea } = Input;


const ReportProblem = (props) => {
    const [reportSuccess, setReportSuccess] = useState(false);
    const [contentError, setContentError] = useState('');
    const [reportMessage, setReportMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [dataReport, setDataReport] = useState({
      partnerEmail: null,
      partnerName: null,
      actionNumber: null,
      uniqueId: null,
      message: null,
      issueSubject: null,
    })
    const user = useSelector(state => state.userReducer.userInfo)

    const back = () => {
      props.history.push('/reservas/lista')
    }

    const sendReserve = async () => {
     
    //  console.log(dataReport)
      if (dataReport.issueSubject === null || dataReport.issueSubject === '') {
        setContentError('Debe seleccionar el problema');
        setShowModal(true);
      } else if (dataReport.responsable === null || dataReport.responsable === '') {
        setContentError('Debe colocar el responsable');
        setShowModal(true);
      } else if (dataReport.message === null || dataReport.message === '') {
        setContentError('Debe describir su problema');
        setShowModal(true);
      } else {
        setLoadingButton(true)
        try {
          const response = await request('POST', '/report-issue', dataReport)
          const data = await response.json()
          // console.log(data)
          if (response.status === 200) {
            setShowModal(false);
            setLoadingButton(false)
            setReportSuccess(true);
            setReportMessage(data.message);
            
          } else {
            setContentError(data.message);
            setShowModal(true);
            setLoadingButton(false)
          }
        } catch (error) {
          setContentError('Error al reportar un problema, intentelo más tarde.');
          setShowModal(true);
          setLoadingButton(false)
        }
      }
    }

    const changeDataResponsable = (e) => {
      // console.log('e,', e)
      let currentUser = user.FAMILIARES.find(user => user.IDUNICO === e)
      if(currentUser === undefined) {
        currentUser = user
      } 
      // console.log('currentUser', currentUser)
      setDataReport({
        ...dataReport,  
        partnerEmail: currentUser.CORREO,
        partnerName: currentUser.NOMBRES,
        actionNumber: currentUser.ACCION,
        uniqueId: currentUser.IDUNICO,
      })
    }
    return (
      <ReserveWrapper centerX>
        <ReserveMainTitle title="Mis reservas: " breadcrumb={['Inicio > ', 'Reportar un problema']}/>
        <div className='box mb-30'>
          <ReserveInnerTitle activity={false} title='REPORTAR UN PROBLEMA' subtitle='Llene el siguiente formulario si necesita reportar algún problema con sus reservaciones: '/>
          <div className='main-report-problem'>
            <div className='main-report-problem-parte-1'>
              <div className="mb-15">
                <p className="no-space"><span className="title-small-blue">Acción: </span>{user.ACCION}</p>
                <p className="no-space"><span className="title-small-blue">Titular: </span>{user.NOMBRE}, C.I. {user.CEDULA}</p>
                <p className="no-space"><span className="title-small-blue">Correo: </span>{user.CORREO}</p>
              </div>
            </div>

            <div className='main-report-problem-parte-2 mb-20'>
                <div className="container-input-reserve-report mb-15">
                  <p className='title-small-blue mb-5'>Problema con: </p>
                  <Select placeholder='Seleccione una opción:' className='tenis-select-size tenis-min-select-size' onChange={(e) => setDataReport({...dataReport,  issueSubject: e})}>
                    <Select.Option value="Reservación en la Cancha de Tenis">Reservación en la Cancha de Tenis</Select.Option>
                    <Select.Option value="Reservación en Indoor Cycling">Reservación en Indoor Cycling</Select.Option>
                    {/* <Select.Option value=">Reservación en la Sala TRX">Reservación en la Sala TRX</Select.Option> */}
                    <Select.Option value="Reservación en Otras Salas">Reservación en Otras Salas</Select.Option>
                    <Select.Option value="Reservación en Squash">Reservación en Squash</Select.Option>
                  </Select>
                 </div>
                {/* <div className="mb-5">
                  <div className="container-input-reserve-report">
                    <p className="title-small-blue tenis-min-select-size mb-5">No. de Reservación:</p>
                      <Input placeholder="No. de Reservación: " onChange={(e) => setDataReport({...dataReport,  numberReserversion: e.target.value})} className="tenis-select-size"/>
                    </div>
                </div> */}
                <div className="mb-15">
                <div className='step-resp'>
                  <p className='title-small-blue no-space'>Seleccione el responsable de la reservación</p>
                  <p className='mb-10 small-text'>Titular de la acción o miembros familiares</p>
                  <Select placeholder='Responsable' className='tenis-select-size tenis-min-select-size' onChange={(e) => changeDataResponsable(e)} >
                    <Select.Option value={user.IDUNICO}>{user.NOMBRES} {user.APELLIDOS} {user.CEDULA}</Select.Option>
                    {user.FAMILIARES.map(socio => <Select.Option key={socio.IDUNICO} value={socio.IDUNICO}>{socio.NOMBRES} {socio.APELLIDOS} {socio.CEDULA}</Select.Option>)}
                  </Select>
                  </div>
                </div>
                <div className="mb-15">
                  <div className="container-input-reserve-report">
                    <p className="title-small-blue  mb-5">Reporte el problema:</p>
                      <TextArea onChange={(e) => setDataReport({...dataReport,  message: e.target.value})} placeholder="Describa su problema: " rows={4}/>
                    </div>
                </div>
            </div>
          </div>
          <ReserveInnerFooter back={back} report sendReserve={sendReserve}/>
          {
            reportSuccess && 
            <ReserveSuccessModal report loading={loadingButton} reportMessage={reportMessage} title='REPORTAR UN PROBLEMA'/>
          }
          {showModal && <ModalError content={contentError} setShowModal={setShowModal}/>}
        </div>
      </ReserveWrapper>
    )
}
export default ReportProblem;
