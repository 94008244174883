import React from 'react';
import {Button} from 'antd';
import './ReserveInnerFooter.scss';
import {Link} from 'react-router-dom';

function ReserveInnerFooter(props) {
    return (
        <div className="reserve-inner-footer">
           {!props.notBack && <Link className="reserve-back-button" to='#' onClick={props.back}>Volver al paso anterior</Link>}
           {
             !props.detailsReserve && !props.profile && !props.report && !props.table &&
            <div className="container-btn">
              <Button loading={props.loadingCreateReserve} className="button purple" onClick={props.forward}>{props.lastStep ? 'Confirmar Reserva' : 'Continuar'}</Button>
            </div>
            }
           {props.profile && 
            <div className="container-btn">
              <Button className="button purple"><a href="https://vaac.dyndns.org/user/auth/login" target="_blank" rel="noopener noreferrer">Solicitar actualización de datos</a></Button>
            </div>
           }
           {props.report && 
            <div className="container-btn">
              <Button loading={props.loading} className="button purple" onClick={() => props.sendReserve()}>Enviar Reporte</Button>
            </div>
           }
           {props.detailsReserve &&  /* si es activo y alreadyPassed esta falso */
            props.statusReserve === process.env.REACT_APP_ACTIVE_RESERVATION && props.alreadyPassed === false && 
            <div className="container-btn-edit">
              <Button loading={props.buttonLoadingCancelReason} className="button purple outline" onClick={() => props.setCancelReserveReason(true)}>CANCELAR RESERVA</Button>
              {/* <Button className="button purple" onClick={props.forward}>EDITAR RESERVA</Button> */}
            </div>
           }
        </div>
    )
}
export default ReserveInnerFooter;
