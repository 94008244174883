import React, {useState, useEffect} from 'react';
import { Select } from 'antd';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import {withRouter, Redirect} from 'react-router-dom';
import ModalError from '../../Molecules/ModalError/ModalError';
import {useSelector} from 'react-redux';

function Step2(props) {
    const [responsible, setResponsible] = useState(props.step2Data ? props.step2Data.responsible : null);
    const [contentError, setContentError] = useState('Debe seleccionar un responsable');
    const [showModal, setShowModal] = useState(false);

    const user = useSelector(state => state.userReducer.userInfo)

    useEffect(()=> {
      props.setStep3Data(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const forward = () => {
        if (!responsible) {
          setContentError('Debe seleccionar un responsable');
          setShowModal(true);
        } else {
          setShowModal(false);
          props.saveData({responsible});
          props.history.push('/reservas/indoor-cycling/invitados')
        }
    }
    const back = () => {
        props.history.push('/reservas/indoor-cycling/dia')
    }

    return (
        <>
            {props.step1Data ? 
                <>
                    <div className='flex mb-30 step-resp'>
                        <div className='body-space-right step-resp'>
                            <h6 className='title-upper-red'>DETALLES</h6>
                            <p className='no-space'><span className='title-small-blue'>Actividad:</span> Indoor Cycling ({props.step1Data.activity.name})</p>
                            <p className='no-space'><span className='title-small-blue'>Fecha:</span> {props.step1Data.date}</p>
                            <p className='no-space'><span className='title-small-blue'>Horario:</span> {props.step1Data.time.name}</p>
                        </div>
                        <div className='body-space-left step-resp'>
                            <p className='title-small-blue'>Seleccione el responsable de la reservación</p>
                            <p className='small-text'>Titular de la acción o miembros familiares</p>
                            <Select placeholder='Responsable' className='tenis-select-size tenis-min-select-size' onChange={val=>setResponsible(val)} defaultValue={responsible}>
                                <Select.Option value={user.IDUNICO}>{user.NOMBRES} {user.APELLIDOS} {user.CEDULA}</Select.Option>
                               {/* {console.log('user.FAMILIARES', user.FAMILIARES)} */}
                                {user.FAMILIARES.map(socio => <Select.Option key={socio.IDUNICO} value={socio.IDUNICO}>{socio.NOMBRES} {socio.APELLIDOS} {socio.CEDULA}</Select.Option>)}
                            </Select>
                        </div>
                    </div>
                    {showModal && <ModalError activity='cycling' content={contentError} setShowModal={setShowModal}/>}
                    <ReserveInnerFooter back={back} forward={forward} />
                </> 
                : 
                <Redirect to="/" />
            } 
        </>
    )
}
export default withRouter(Step2);
