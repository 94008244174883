import React, { useState } from 'react'
import { Button, Menu } from 'antd';
import { UserOutlined, ShoppingOutlined, LogoutOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './ReserveMenu.scss';
import socioPhoto from '../../../assets/socio.png'
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../../redux/actionsCreators';
const { SubMenu } = Menu;

function ReserveMenu(props) {

  const dispatch = useDispatch()

  const [openKeys, setOpenKeys] = useState([localStorage.getItem('latestOpenKey')])
  let rootSubmenuKeys = ['sub1', 'sub2'];


  const onOpenChange = openKey => {
    const latestOpenKey = openKey.find(key => openKeys.indexOf(key) === -1);
    localStorage.setItem('latestOpenKey', latestOpenKey)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKey);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  };

  const user = useSelector(state => state.userReducer.userInfo)

  // Cerrar sesion
  const logout = () => {
    props.drawer && props.setVisible(false)
    dispatch(logoutUser());
    localStorage.clear();
    props.history.push('/reservas/login');
  }

  return (
    <div className={`container-menu-vertical ${props.hidden && 'hidden'}`}>
      <div className="container-menu-vertical--header">
        <div className="menu-vertical-img">
          <img src={socioPhoto} alt="socio" />
        </div>
        <div className="menu-vertical-data">
          <p className="menu-vertical-data--name">Hola, {user.NOMBRES} {user.APELLIDOS}</p>
          <p>Acción: {user.ACCION}</p>
          {user.CORREO && <p>Correo: {user.CORREO}</p>}
        </div>
      </div>
      <Menu
        mode="inline"
        onOpenChange={onOpenChange}
        openKeys={openKeys}
        selectedKeys={[props.location.pathname]}
        // defaultOpenKeys={[localStorage.getItem('latestOpenKey')]}
      >
        <SubMenu key="sub1" icon={<UserOutlined />} title='Mis datos' >
          <Menu.Item key='/datos/socios'><Link to='/datos/socios'>Acceder a mis datos de socio</Link></Menu.Item>
        </SubMenu>
        <SubMenu key='sub2' icon={<ShoppingOutlined />} title='Mis reservas' onClick={() => props.drawer && props.setVisible(true)}>
          <Menu.Item key='/'><Link to='/'>Realizar una nueva reserva</Link></Menu.Item>
          <Menu.Item key='/reservas/lista'><Link to='/reservas/lista'>Listado de reservas</Link></Menu.Item>
          <Menu.Item key='/reservas/reportar-problema'><Link to='/reservas/reportar-problema'>Reportar un problema</Link></Menu.Item>
        </SubMenu>
        {/* <Menu.Item key="/reservas/login" icon={<LogoutOutlined />} className="sub-rol-item">
          <a href={`https://vaac.dyndns.org/site/logintoken?access_token=${localStorage.getItem('token')}`} target="_blank" rel="noopener noreferrer">Sistema de Autogestión</a>
        </Menu.Item> */}
        <Menu.Item key="/reservas/login" icon={<LogoutOutlined />} className="sub-rol-item" onClick={() => logout()}>
          Cerrar sesión
        </Menu.Item>
        <div className="button-portal-desktop mt-20 mb-50 center-text "><a href='https://vaac.com.ve/'><div className="button-portal"><Button className="button turquoise outline ">Ir al Portal de Socios</Button></div></a></div>
      </Menu>
    </div>
  )
}

export default ReserveMenu
