import React, {useState, useEffect} from 'react';
import { Radio, Select } from 'antd';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import {withRouter} from 'react-router-dom';
import './Step1.scss';
import ModalError from '../../Molecules/ModalError/ModalError';
import request from '../../../services/request';
import {getAvailableDays, getDate} from '../../../services/helpers';

function Step1(props) {
    const [day, setDay] = useState(props.step1Data ? props.step1Data.day : null);
    const [time, setTime] = useState(props.step1Data ? props.step1Data.time : null);
    const [field, setField] = useState(props.step1Data ? props.step1Data.field : null);
    const [availableDays, setAvailableDays] = useState([]);
    const [contentError, setContentError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [block, setBlock] = useState(props.step1Data ? props.step1Data.block : null);
    const [fieldList, setFieldList] = useState([]);
    const [scheduleList, setScheduleList] = useState([])
    
    const forward = () => {
        if (!field) {
          setContentError('Debe seleccionar una cancha');
          setShowModal(true);
        } else if (!day) {
          setContentError('Debe seleccionar un día');
          setShowModal(true);
        } else if (!time) {
          setContentError('Debe seleccionar una hora');
          setShowModal(true);
        } else {
            setShowModal(false);
            props.saveData({
                day, time, block, field, date: getDate(day.id)
            })
            props.history.push('/reservas/squash/responsable')
        }
    }
    const back = () => {
        props.history.push('/')
    }

    /*const availableTime = option => {
        const date = new Date();
        let available = true;
        const optionTime = option.getTime() / 60000;
        const currentTime = date.getTime() / 60000;
        if (optionTime - currentTime < 20) {
            available = false;
        }
        return available
    }*/

    useEffect(()=> {
      props.setStep2Data(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(()=>{
        //validacion de que solo se puede reservar dia actual y siguiente a partir de las 8am
        setAvailableDays(getAvailableDays());
    }, [])

    useEffect(() => {
        //obtengo listado de canchas
        const fieldsRequest = async () => {
            const response = await request('GET', '/rooms-by-association/4/null');
            if (response.ok) {
                const data = await response.json();
                setFieldList(data.rooms)
            }
        }
        fieldsRequest()
    }, [])

    useEffect(() => {
        //obtengo horarios
        const schedulesRequest = async () => {
            const response = await request('GET', `/schedules-by-association/${field.id}/4/null/${day.id}/null/200/0`);
            if (response.ok) {
                const data = await response.json();
                if (data.schedules && data.schedules.rows) {
                    const filteredRows = data.schedules.rows.filter(schedule => schedule.available)
                    setScheduleList(filteredRows)
                    if (filteredRows.length === 0) {
                        setContentError('No hay horarios disponibles para la cancha y día seleccionado');
                        setShowModal(true);
                    }
                }
            }
        }
        if (field && field.id && day && day.id) {
            schedulesRequest()
        }
    }, [field, day])

    return (
        <>
            <div className='flex mb-30 step-resp'>
                <div className='body-space-right step-resp'>
                    <Radio.Group value={field && field.id}>
                        {fieldList.filter(field=>field.room.available === true).map(act=>(
                            <div key={act.room.id} className={!field || field.id !== act.room.id ? 'active-radio mb-10' : 'active-radio-check mb-10'} onClick={()=>{setField({id: act.room.id, name: act.room.name}); setTime(null); setBlock(null)}}>
                                <Radio value={act.room.id}>{act.room.name.toUpperCase()}</Radio>
                            </div>
                        ))}
                    </Radio.Group>
                </div>
                <div className='body-space-left step-resp'>
                    <p className='title-small-blue no-space'>Seleccione el día:</p>
                    <p className='small-text mb-10'>Solo puede reservar con un día de antelación</p>
                    <Select placeholder='Seleccionar día' className='tenis-select-size' labelInValue onChange={val=>{setDay({id: val.value, name: val.label}); setTime(null); setBlock(null)}} defaultValue={{value: day && day.id}}>
                        <Select.Option value={1} disabled={!availableDays.includes("Lunes")}>Lunes</Select.Option>
                        <Select.Option value={2} disabled={!availableDays.includes("Martes")}>Martes</Select.Option>
                        <Select.Option value={3} disabled={!availableDays.includes("Miércoles")}>Miércoles</Select.Option>
                        <Select.Option value={4} disabled={!availableDays.includes("Jueves")}>Jueves</Select.Option>
                        <Select.Option value={5} disabled={!availableDays.includes("Viernes")}>Viernes</Select.Option>
                        <Select.Option value={6} disabled={!availableDays.includes("Sábado")}>Sábado</Select.Option>
                        <Select.Option value={7} disabled={!availableDays.includes("Domingo")}>Domingo</Select.Option>
                    </Select>
                    <div className='flex step1-time-container mt-25'>
                        <div className={block === 1 ? 'block-active-check' : 'block-active'}>
                            <div>
                                <p className='title-small-blue mb-15'>Mañana (bloque 1)</p>
                                <Select placeholder='Seleccionar hora' className='tenis-select-size' labelInValue onChange={val=>{setTime({id: val.value, name: val.label}); setBlock(1)}} value={block === 1 ? {value: time && time.id} : {value: null}}>
                                    {scheduleList
                                        .filter(schedule=>schedule.schedule.scheduleBlock === 'am')
                                        .map(schedule=>
                                            <Select.Option value={schedule.schedule.id} key={schedule.schedule.id}>
                                                {schedule.schedule.openingTime}{schedule.schedule.closingTime ? ' - ' + schedule.schedule.closingTime : ''} am
                                            </Select.Option>
                                        )
                                    }
                                </Select>
                            </div>
                        </div>
                        <div className={block === 2 ? 'block-active-check' : 'block-active'}>
                            <div>
                                <p className='title-small-blue mb-15'>Tarde (bloque 2)</p>
                                <Select placeholder='Seleccionar hora' className='tenis-select-size' labelInValue onChange={val=>{setTime({id: val.value, name: val.label}); setBlock(2)}} value={block === 2 ? {value: time && time.id} : {value: null}}>
                                    {scheduleList
                                        .filter(schedule=>schedule.schedule.scheduleBlock === 'pm')
                                        .map(schedule=>
                                            <Select.Option value={schedule.schedule.id} key={schedule.schedule.id}>
                                                {schedule.schedule.openingTime}{schedule.schedule.closingTime ? ' - ' + schedule.schedule.closingTime : ''} pm
                                            </Select.Option>)
                                    }
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && <ModalError activity='squash' content={contentError} setShowModal={setShowModal}/>}
            <ReserveInnerFooter back={back} forward={forward} />  
        </>
    )
}
export default withRouter(Step1);
