import React, { useState } from 'react';
import {Table, Button, Form, Select, Tabs} from 'antd';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import './ReserveList.scss';
import {useSelector} from 'react-redux';
import { getActivityType } from '../../../services/helpers';
import useReservations from '../../../customHooks/useReservations';
import Spinner from '../../Atoms/Spinner/Spinner';

const columns = [
  {
    title: 'N° Reserva',
    dataIndex: 'number',
    key: 'number',
    fixed: 'left',
    width: 110
  },
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Hora',
    dataIndex: 'time',
    key: 'time'
  },
  {
    title: 'Actividad',
    dataIndex: 'activity',
    key: 'activity'
  },
  {
    title: 'Detalles',
    dataIndex: 'details',
    key: 'details',
    fixed: 'right',
    width: 100
  }
];

function ReserveList() {
  const [currentTab, setCurrentTab] = useState('Activa');
  const [sportId, setSportId] = useState(null);
  const user = useSelector(state => state.userReducer.userInfo)
  const activityType = getActivityType({sportId})

  // Custom Hooks para obtener reservaciones por socio
  const { reserveList, loadingReservations, loadingReservationsNext, reserveCount, reserveCountRow, setPage } = useReservations({ action: user.ACCION, sportId, status: currentTab })
  
  // Cambiar el deporte
  const changeSport = (value) => {
    setSportId(value);
    setPage(0)
  }
  // Cambiar el status
  const changeStatus = (value) => {
    setCurrentTab(value);
    setPage(0)
  }

	return (
		<ReserveWrapper centerX>
			<ReserveMainTitle title='Mis reservas:' breadcrumb={['Inicio > ', `Listado de reservas`]}/>
			<div className='box mb-20'>
				<ReserveInnerTitle activity={activityType.shortname} title='Listado de reservas' subtitle='A continuación una lista de sus reservaciones'/>
					<Form initialValues={{activity: null}}>
						<Form.Item label='Actividad' name='activity' className='input-filter'>
	            <Select onChange={value => changeSport(value)} placeholder='Actividad'>
	              <Select.Option value='1'>Tenis</Select.Option>
	              <Select.Option value='2'>Indoor Cycling</Select.Option>
                <Select.Option value='3'>Otras Salas</Select.Option>
	              <Select.Option value='4'>Squash</Select.Option>
	              <Select.Option value={null}>Ver todos</Select.Option>
	            </Select>
	          </Form.Item>
					</Form>
					<Tabs defaultActiveKey="Activa" centered onChange={tab => changeStatus(tab)}>
	          <Tabs.TabPane tab="Reservas activas" key="Activa">
	            <Table loading={loadingReservations} scroll={{ x: 620 }} rowClassName={(a,b)=>b%2 === 0 && 'bg-light'} className='reserve-active-table' columns={columns} dataSource={reserveList} pagination={false}/>
	          </Tabs.TabPane>
	          <Tabs.TabPane tab="Historial de reservas" key="Usada">
	            <Table loading={loadingReservations} scroll={{ x: 620 }} rowClassName={(a,b)=>b%2 === 0 && 'bg-light'} className='reserve-active-table' columns={columns} dataSource={reserveList} pagination={false}/>
	          </Tabs.TabPane>
	        </Tabs>
					{loadingReservationsNext ? <Spinner small_x2 /> : ((reserveList.length !== reserveCount) && (reserveCountRow > 0)) && <Button className="button turquoise outline center-btn mt-20" onClick={() => setPage(prevPage => prevPage + 1)}>Mostrar más</Button>}
			</div>
		</ReserveWrapper>
	)
}

export default ReserveList;
