import React, {useState} from 'react';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

function ReserveSquash(props) {
    const [step1Data, setStep1Data] = useState(null)
    const [step2Data, setStep2Data] = useState(null)
    const [step3Data, setStep3Data] = useState(null)

    const steps = {
        '/reservas/squash/dia': {
            subtitle: 'Seleccione la Cancha y horario disponible para realizar su reserva:',
            component: <Step1 saveData={setStep1Data} step1Data={step1Data} setStep2Data={setStep2Data} />
        },
        '/reservas/squash/responsable': {
            subtitle: 'Indique el responsable de la reservación (titular o miembro familiar de la acción):',
            component: <Step2 saveData={setStep2Data} step1Data={step1Data} step2Data={step2Data} setStep3Data={setStep3Data} />
        },
        '/reservas/squash/invitados': {
            subtitle: 'Indique el número de jugadores (pareja o dobles) y número de cédula del socio o miembro familiar a invitar a la partida:',
            component: <Step3 saveData={setStep3Data} step1Data={step1Data} step2Data={step2Data} step3Data={step3Data} />
        },
        '/reservas/squash/confirmacion': {
            subtitle: 'Confirme su reserva para usted y su invitado (socio o familiar): ',
            component: <Step4 step1Data={step1Data} step2Data={step2Data} step3Data={step3Data} />
        }
    }
    // console.log(props)

    return (
        <ReserveWrapper centerX>
            <ReserveMainTitle title="Realice una reserva:" breadcrumb={['Inicio > ', 'Reservaciones > ', 'Squash']}/>
            <div className='box mb-30'>
                <ReserveInnerTitle activity='squash' title='RESERVA DE CANCHAS DE SQUASH' subtitle={steps[props.history.location.pathname].subtitle}/>
                {steps[props.history.location.pathname].component}
            </div>
        </ReserveWrapper>   
    )
}
export default ReserveSquash;
