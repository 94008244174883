function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// Funcion que registra al usario para las notificaciones
export const registerPush = () => {
  navigator.serviceWorker.register(
    `${process.env.PUBLIC_URL}/serviceworker.js`
  );
  navigator.serviceWorker.ready.then(function (registration) {
    return registration.pushManager
      .getSubscription()
      .then(function (subscription) {
        // console.log('subscription', subscription)
        if (subscription) {
          return subscription;
        }
        return registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_WEB_PUSH_PUBLIC_KEY),
        });
      })
      .then(function (subscription) {
        return fetch(`${process.env.REACT_APP_API_URL}/notification-subscribe`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("dashboardToken")}`,
          },
          body: JSON.stringify({
            subscription
          }),
        })
          .then((resp) => resp.json())
          .then((resp) => {
            // console.log(resp);
            if (resp.message === "Proceso exitoso.") {
              // console.log('Subscrito correctamente')
            } else {
              // console.log('No se pudo hacer la subscripcion')
            }
          });
      })
      .catch((err) => console.log(err));
  });
};

