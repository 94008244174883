import React, {useState, useEffect} from 'react';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import {withRouter, Redirect} from 'react-router-dom';
import './Step4.scss';
import ModalError from '../../Molecules/ModalError/ModalError';
import Spinner from '../../Atoms/Spinner/Spinner';
import SeatMap from '../../Organisms/SeatMap/SeatMap';
import request from '../../../services/request';
import {useSelector} from 'react-redux';
import { getResponsibleUser } from '../../../services/helpers';

function Step4(props) {
  const [loading, setLoading] = useState(true);
  const [seats, setSeats] = useState(props.step4Data ? props.step4Data.seats : []);
  const [contentError, setContentError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [seatList, setSeatList] = useState(null);

  const user = useSelector(state => state.userReducer.userInfo)

  let responsable = {};
  if (props.step1Data) {
    responsable = getResponsibleUser(props.step2Data.responsible, user)
  }

  const saveSeat= seatID => {
    setSeats(prevSeats => {
      if (prevSeats.includes(seatID)) {
        //eliminar seleccion
        return prevSeats.filter(id => id !== seatID)
      } else {
        if (prevSeats.length === props.step3Data.players) {
          //eliminar primer asiento y agregar el ultimo
          return prevSeats.concat(seatID).slice(1);
        } else {
          //agregar nuevo asiento.
          return prevSeats.concat(seatID);
        }
      }
    })
  }
  const back = () => {
    props.history.push('/reservas/indoor-cycling/invitados')
  }
  const forward = () => {
    if (seats.length !== props.step3Data.players) {
      setContentError(`Debe selecionar ${props.step3Data.players} bicicleta${props.step3Data.players > 1 ? 's':''}`);
      setShowModal(true);
    } else {
      props.saveData({seats})
      setShowModal(false);
      props.history.push('/reservas/indoor-cycling/confirmación')
    }
  }

 

  useEffect(()=>{
      //obtengo la estructura de la sala basado en step1Data.sala
      /*const seatMap1 = [ //aforo completo
          [null, null, {id: 4}, null, null, null, null, null],
          [null, {id: 3}, null, null, null, null, null, null],
          [{id: 2}, null, null, null, null, null, null, {id: 5} ],
          [{id: 1}, null, null, null, null, null, null, {id: 6}],
          [null, {id: 7}, {id: 8}, {id: 9}, {id: 10}, {id: 11}, {id: 12}, null],
          [null, {id: 13}, {id: 14}, {id: 15}, {id: 16}, {id: 17}, {id: 18}, null],
          [null, {id: 19}, {id: 20}, {id: 21}, {id: 22}, {id: 23}, {id: 24}, null],
          [null, {id: 25}, {id: 26},  {id: 27}, {id: 28},{id: 29}, {id: 30}, null]
      ]
      const seatMap2 = [ //estacionamiento
          [null, null, null, null, {instructor: true}, null, null, null, null],
          [],
          [{id: 1}, null, null, {id: 2}, null, {id: 3}, null, null, {id: 4}],
          [],
          [null, {id: 5}, null, null, {id: 6}, null, null, {id: 7}, null],
          [],
          [null, null, {id: 8}, null, {id: 9}, null, {id: 10}, null, null],
      ]
      const seatMap3 = [ //gimnasio
          [null, null, null, {id: 2}, null, {id: 4}, null,{id: 6}, null, {id: 8}],
          [{instructor: true},null, null, null, null, null, null, null, null, null],
          [null, null, {id: 1}, null, {id: 3}, null, {id: 5}, null, {id: 7}, null],
      ]
      const seatMap4 = [ //aforo limitado
          [null, null, {id: 2}, null, {instructor: true}, null, null, null, null],
          [{id: 1}, null, null, null, null, null, null, null, {id: 3}],
          [],
          [null, {id: 4}, null, {id: 5}, null, {id: 6}, null, {id: 7}, null],
          [],
          [null, null, {id: 8}, null, {id: 9}, null, {id: 10}, null, null],
      ]
      const mapIndex = {
        1: seatMap1,
        2: seatMap2,
        3: seatMap3,
        4: seatMap4,
      }
      if (props.step1Data) {
        setSeatList(mapIndex[props.step1Data.sala]);
      }*/
      const mapRequest = async () => {
        const response = await request('POST', '/map', {
          roomId: props.step1Data.sala.id,
          activityId: props.step1Data.activity.id,
          sportId: 2,
          scheduleId: props.step1Data.time.id
        })
        if (response.ok) {
          const data = await response.json();
          setSeatList(data.map)
          setLoading(false)
        }
      }
      if (props.step1Data) {
        mapRequest()
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {
        props.step1Data ? 
        <>
          {!loading ?
            <>
              <div className="container-reserve-indoor-step-4">
                <div className="reserve-indoor-step-4-parte-1">
                  <h6 className="title-upper-red">DETALLES</h6>
                  {/* {console.log('responsable', responsable)} */}
                    <div className="mb-20">
                      <p className="data"><span className="title-small-blue">Actividad:</span> Indoor Cycling ({props.step1Data.activity.name})</p>
                      <p className="data"><span className="title-small-blue">Fecha:</span> {props.step1Data.date}</p>
                      <p className="data"><span className="title-small-blue">Horario:</span> {props.step1Data.time.name}</p>
                    </div>
                    <div className="mb-20">
                      <p className="data"><span className="title-small-blue">Responsable de la reserva (Participante 1):</span> Acción No. {responsable.accion}, {responsable.name} {responsable.lastName}, C.I. {responsable.cedula} ({responsable.parentezco})</p>
                    </div>
                    {props.step3Data.players === 2 && <div className="mb-20">
                      <p className="data"><span className="title-small-blue">Participante 2:</span> {props.step3Data.player2.firstName} {props.step3Data.player2.lastName} {props.step3Data.player2.legalId} </p>
                    </div>}
                </div>
                <div className="reserve-indoor-step-4-parte-2 ">
                  <div className="reserve-indoor-status mb-20">
                    <div className="reserve-indoor-status--item turquoise"><p className="data">Disponible</p></div>
                    <div className="reserve-indoor-status--item steelblue"><p className="data">Seleccionada</p></div>
                    <div className="reserve-indoor-status--item gray"><p className="data">No disponible</p></div>
                  </div>
                  {/*<SeatMap seatList={seatList} reservedSeatList={reservedSeatList} selectedSeats={seats} saveSeat={saveSeat} />*/}
                  <SeatMap seatList={seatList} selectedSeats={seats} saveSeat={saveSeat} />
                </div>
              </div>
              {showModal && <ModalError activity='cycling' content={contentError} setShowModal={setShowModal}/>}
              <ReserveInnerFooter back={back} forward={forward} />
            </>
            :
            <Spinner small />
          }
        </> 
        : 
        <Redirect to="/" />
      } 
    </>
  )
}

export default withRouter(Step4);
