import React, {useState, useEffect} from 'react';
import { Select } from 'antd';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import {withRouter} from 'react-router-dom';
import ModalError from '../../Molecules/ModalError/ModalError';
import request from '../../../services/request';
import {getAvailableDays, getDate} from '../../../services/helpers';

function Step1(props) {
    const [activity, setActivity] = useState(props.step1Data ? props.step1Data.activity : null);
    const [day, setDay] = useState(props.step1Data ? props.step1Data.day : null);
    const [time, setTime] = useState(props.step1Data ? props.step1Data.time : null);
    const [availableDays, setAvailableDays] = useState([]);
    const [contentError, setContentError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [activityList, setActivityList] = useState([])
    const [scheduleList, setScheduleList] = useState([])
    
    const forward = () => {
        if (!activity) {
          setContentError('Debe seleccionar una actividad');
          setShowModal(true);
        } else if (!day) {
          setContentError('Debe seleccionar un día');
          setShowModal(true);
        } else if (!time) {
          setContentError('Debe seleccionar una hora');
          setShowModal(true);
        } else {
            setShowModal(false);
            props.saveData({
                activity, day, time, date: getDate(day.id)
            })
            props.history.push('/reservas/otros/responsable')
        }
    }
    const back = () => {
        props.history.push('/')
    }

    /*const availableTime = option => {
        const date = new Date();
        let available = true;
        const optionTime = option.getTime() / 60000;
        const currentTime = date.getTime() / 60000;
        if (optionTime - currentTime < 20) {
            available = false;
        }
        return available
    }*/

    useEffect(()=> {
      props.setStep2Data(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(()=>{
        //validacion de que solo se puede reservar dia actual y siguiente a partir de las 8am
        setAvailableDays(getAvailableDays());
    }, [])

    useEffect(() => {
        //obtengo listado de actividades
        const activitiesRequest = async () => {
            const response = await request('GET', '/sports');
            if (response.ok) {
                const data = await response.json();
                setActivityList(data.sports[2].activity)
            }
        }
        activitiesRequest()
    }, [])

    useEffect(() => {
        //obtengo horarios
        const schedulesRequest = async () => {
            const response = await request('GET', `/schedules-by-association/null/3/${activity.id}/${day.id}/null/200/0`);
            if (response.ok) {
                const data = await response.json();
                if (data.schedules && data.schedules.rows) {
                    const filteredRows = data.schedules.rows.filter(schedule => schedule.available)
                    setScheduleList(filteredRows)
                    if (filteredRows.length === 0) {
                        setContentError('No hay horarios disponibles para la actividad y día seleccionado');
                        setShowModal(true);
                    }
                }
            }
        }
        if (activity && activity.id && day && day.id) {
            schedulesRequest()
        }
    }, [activity, day])

    return (
        <>
            <div className='flex mb-30 step-resp'>
                <div className='body-space-right step-resp mb-15'>
                    {/* {console.log(activityList)} */}
                    <Select placeholder='Seleccionar actividad' className='tenis-select-size' labelInValue onChange={val=>{setActivity({id: val.value, name: val.label}); setTime(null)}} defaultValue={{value: activity && activity.id}}>
                        {activityList.filter(act=>act.available === true).map(act=><Select.Option value={act.id} key={act.id}>{act.name}</Select.Option>)}
                    </Select>
                </div>
                <div className='body-space-left step-resp'>
                    <div>
                        <p className='title-small-blue no-space'>Seleccione el día:</p>
                        <p className='small-text mb-10'>Solo puede reservar con un día de antelación</p>
                        <Select placeholder='Seleccionar día' className='tenis-select-size' labelInValue onChange={val=>{setDay({id: val.value, name: val.label}); setTime(null)}} defaultValue={{value: day && day.id}}>
                            <Select.Option value={1} disabled={!availableDays.includes("Lunes")}>Lunes</Select.Option>
                            <Select.Option value={2} disabled={!availableDays.includes("Martes")}>Martes</Select.Option>
                            <Select.Option value={3} disabled={!availableDays.includes("Miércoles")}>Miércoles</Select.Option>
                            <Select.Option value={4} disabled={!availableDays.includes("Jueves")}>Jueves</Select.Option>
                            <Select.Option value={5} disabled={!availableDays.includes("Viernes")}>Viernes</Select.Option>
                            <Select.Option value={6} disabled={!availableDays.includes("Sábado")}>Sábado</Select.Option>
                            <Select.Option value={7} disabled={!availableDays.includes("Domingo")}>Domingo</Select.Option>
                        </Select>
                    </div>
                    <div className='mt-25'>
                        <div>
                            <p className='title-small-blue mb-5'>Horarios disponibles: </p>
                            <Select placeholder='Seleccionar hora' className='tenis-select-size tenis-min-select-size' labelInValue onChange={val=>setTime({id: val.value, name: val.label})} value={{value: time && time.id}}>
                                {scheduleList.map(schedule=>
                                  <Select.Option value={schedule.schedule.id} key={schedule.schedule.id}>
                                    {schedule.schedule.openingTime}{schedule.schedule.closingTime ? ' - ' + schedule.schedule.closingTime : ''}{' '+schedule.schedule.scheduleBlock}{schedule.instructor && ' , '+schedule.instructor.firstName} {schedule.instructor && schedule.instructor.lastName}  {schedule.specificLocation && schedule.specificLocation}
                                  </Select.Option>)
                                }
                            </Select>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && <ModalError activity='others' content={contentError} setShowModal={setShowModal}/>}
            <ReserveInnerFooter back={back} forward={forward} />
        </>
    )
}
export default withRouter(Step1);
