import React, {useState} from 'react';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

function ReserveOthers(props) {
    const [step1Data, setStep1Data] = useState(null)
    const [step2Data, setStep2Data] = useState(null)
    
    const steps = {
        '/reservas/otros/dia': {
            subtitle: 'Seleccione día y horario disponible para realizar su reserva:',
            component: <Step1 saveData={setStep1Data} step1Data={step1Data} setStep2Data={setStep2Data} />
        },
        '/reservas/otros/responsable': {
            subtitle: 'Indique el responsable de la reservación (titular o miembro familiar de la acción):',
            component: <Step2 saveData={setStep2Data} step1Data={step1Data} step2Data={step2Data} />
        },
        '/reservas/otros/confirmacion': {
            subtitle: 'Confirme su reserva',
            component: <Step3 step1Data={step1Data} step2Data={step2Data}/>
        }
    }

    return (
        <ReserveWrapper centerX>
            <ReserveMainTitle title="Realice una reserva:" breadcrumb={['Inicio > ', 'Reservaciones > ', 'Otros']}/>
            <div className='box mb-30'>
                {<ReserveInnerTitle activity='others' title='RESERVA DE OTRAS SALAS' subtitle={steps[props.history.location.pathname].subtitle}/>}
                {steps[props.history.location.pathname].component}
            </div>
        </ReserveWrapper>   
    )
}
export default ReserveOthers;
