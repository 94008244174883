import React, {useState, useEffect} from 'react';
import { Radio, Select } from 'antd';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import {withRouter} from 'react-router-dom';
import ModalError from '../../Molecules/ModalError/ModalError';
import request from '../../../services/request';
import {getAvailableDays, getDate} from '../../../services/helpers';

function Step1(props) {
    const [activity, setActivity] = useState(props.step1Data ? props.step1Data.activity : null);
    const [sala, setSala] = useState(props.step1Data ? props.step1Data.sala : null)
    const [day, setDay] = useState(props.step1Data ? props.step1Data.day : null);
    const [time, setTime] = useState(props.step1Data ? props.step1Data.time : null);
    const [availableDays, setAvailableDays] = useState([]);
    const [contentError, setContentError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [activityList, setActivityList] = useState([]);
    const [salaList, setSalaList] = useState([]);
    const [scheduleList, setScheduleList] = useState([])
    
    const forward = () => {
        if (!activity) {
          setContentError('Debe seleccionar una actividad');
          setShowModal(true);
        } else if (!sala)  {
          setContentError('Debe seleccionar una sala');
          setShowModal(true);
        } else if (!day) {
          setContentError('Debe seleccionar un día');
          setShowModal(true);
        } else if (!time) {
          setContentError('Debe seleccionar una hora');
          setShowModal(true);
        } else {
            setShowModal(false);
            props.saveData({
                activity, sala, day, time, date: getDate(day.id)
            })
            props.history.push('/reservas/indoor-cycling/responsable')
        }
    }
    const back = () => {
        props.history.push('/')
    }
    const handleActivity = clickedActivity => {
        if (!activity || activity.id !== clickedActivity.id) {
            setActivity(clickedActivity);
            setSala(null)
            setTime(null);
        }
    }

    /*const availableTime = option => {
        const date = new Date();
        let available = true;
        const optionTime = option.getTime() / 60000;
        const currentTime = date.getTime() / 60000;
        if (optionTime - currentTime < 20) {
            available = false;
        }
        return available
    }*/

    useEffect(()=> {
      props.setStep2Data(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(()=>{
        //validacion de que solo se puede reservar dia actual y siguiente a partir de las 8am
        setAvailableDays(getAvailableDays());
    }, [])

    useEffect(() => {
        //obtengo listado de actividades
        const activitiesRequest = async () => {
            const response = await request('GET', '/sports');
            if (response.ok) {
              const data = await response.json();
              // console.log(data)
              setActivityList(data.sports[1].activity)
            }
        }
        activitiesRequest()
    }, [])

    useEffect(() => {
        //obtengo listado de salas
        const roomRequest = async () => {
            const response = await request('GET', `/rooms-by-association/null/${activity.id}`,);
            if (response.ok) {
                const data = await response.json();
                setSalaList(data.rooms)
            } 
        }
        if (activity) {
            roomRequest()
        }
    }, [activity])

    useEffect(() => {
        //obtengo horarios
        const schedulesRequest = async () => {
            const response = await request('GET', `/schedules-by-association/${sala.id}/2/${activity.id}/${day.id}/null/200/0`);
            if (response.ok) {
                const data = await response.json();
                if (data.schedules && data.schedules.rows) {
                    const filteredRows = data.schedules.rows.filter(schedule => schedule.available)
                    setScheduleList(filteredRows)
                    if (filteredRows.length === 0) {
                        setContentError('No hay horarios disponibles para la actividad, sala y día seleccionado');
                        setShowModal(true);
                    }
                }
            }
        }
        if (activity && activity.id && sala && sala.id && day && day.id) {
            schedulesRequest()
        }
    }, [activity, sala, day])

    return (
        <>
            <div className='flex mb-30 step-resp'>
                <div className='body-space-right step-resp mb-15'>
                    <Radio.Group value={activity && activity.id}>
                        {activityList.filter(act=>act.available === true).map(act=>(
                            <div key={act.id} className={!activity || activity.id !== act.id ? 'active-radio big-radio mb-10' : 'active-radio-check big-radio mb-10' } onClick={()=>handleActivity({name: act.name, id: act.id})}>
                                <Radio value={act.id}>{act.name.toUpperCase()}</Radio>
                            </div>
                        ))}
                    </Radio.Group>
                </div>
                <div className='body-space-left step-resp'>
                    <div className='mb-15'>
                      <div>
                        <p className='title-small-blue mb-5'>Seleccione la sala:</p>
                          <Select placeholder='Seleccionar la sala' className='tenis-select-size tenis-min-select-size' labelInValue onChange={val=>{setSala({id: val.value, name: val.label}); setTime(null)}} value={{value: sala && sala.id}}>
                            {salaList.filter(act=>act.room.available === true).map(act=>(
                                <Select.Option key={act.room.id} value={act.room.id}>{act.room.name}</Select.Option>
                            ))}
                          </Select>
                      </div>
                    </div>
                    <div>
                        <p className='title-small-blue no-space'>Seleccione el día:</p>
                        <p className='small-text mb-10'>Solo puede reservar con un día de antelación</p>
                        <Select placeholder='Seleccionar día' className='tenis-select-size tenis-min-select-size' labelInValue onChange={val=>{setDay({id: val.value, name: val.label}); setTime(null)}} defaultValue={{value: day && day.id}}>
                            <Select.Option value={1} disabled={!availableDays.includes("Lunes")}>Lunes</Select.Option>
                            <Select.Option value={2} disabled={!availableDays.includes("Martes")}>Martes</Select.Option>
                            <Select.Option value={3} disabled={!availableDays.includes("Miércoles")}>Miércoles</Select.Option>
                            <Select.Option value={4} disabled={!availableDays.includes("Jueves")}>Jueves</Select.Option>
                            <Select.Option value={5} disabled={!availableDays.includes("Viernes")}>Viernes</Select.Option>
                            <Select.Option value={6} disabled={!availableDays.includes("Sábado")}>Sábado</Select.Option>
                            <Select.Option value={7} disabled={!availableDays.includes("Domingo")}>Domingo</Select.Option>
                        </Select>
                    </div>
                    <div className='mt-25'>
                        <div>
                            <p className='title-small-blue mb-5'>Horarios disponibles: </p>
                            <Select placeholder='Seleccionar hora' className='tenis-select-size tenis-min-select-size' labelInValue onChange={val=>setTime({id: val.value, name: val.label})} value={{value: time && time.id}}>
                                {scheduleList.map(schedule=>
                                    <Select.Option value={schedule.schedule.id} key={schedule.schedule.id}>
                                        {schedule.schedule.openingTime}{schedule.schedule.closingTime ? ' - ' + schedule.schedule.closingTime : ''} {schedule.schedule.scheduleBlock}{schedule.instructor && ' , '+schedule.instructor.firstName} {schedule.instructor && schedule.instructor.lastName}
                                    </Select.Option>
                                )}
                            </Select>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && <ModalError activity='cycling' content={contentError} setShowModal={setShowModal}/>}
            <ReserveInnerFooter back={back} forward={forward} />
        </>
    )
}
export default withRouter(Step1);
