import React from 'react';
import ReserveSelectionBox from '../../Molecules/ReserveSelectionBox/ReserveSelectionBox';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import './ReserveHome.scss';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import icon1 from '../../../assets/icon-tenis.svg'
import icon2 from '../../../assets/icon-cycling.svg'
import icon3 from '../../../assets/icon-squash.svg'
import icon4 from '../../../assets/icon-others.svg'
import logo from '../../../assets/logo-vaac-blue.svg';
import Spinner from '../../Atoms/Spinner/Spinner';
import { useSetting } from '../../../customHooks/useSettings';

function ReserveHome(props) {

    const { bloqued, loadingBloqued } = useSetting()

    const status = props.match.params.status;
    const reserveOptions = {
        'Canchas de tenis': {
            url: status === 'activas' ? '/reservas/tenis/activas' : '/reservas/tenis/dia',
            icon: icon1
        },
        'Indoor Cycling': {
            url: status === 'activas' ? '/reservas/indoor-cycling/activas' : '/reservas/indoor-cycling/dia',
            icon: icon2
        },
        'Canchas de Squash': {
            url: status === 'activas' ? '/reservas/squash/activas' : '/reservas/squash/dia',
            icon: icon3
        },
        'Otras Salas': {
            url: status === 'activas' ? '/reservas/otros/activas' : '/reservas/otros/dia',
            icon: icon4
        },
    }
    return (
        <ReserveWrapper centerX>
            {loadingBloqued ? <Spinner small/> : 
              bloqued
              ?
              <div className="bloqued-reserves box">
                <div className="center-btn"><img src={logo} alt="Logo Vaac" className="modal-body-reserves--logo"/></div>
                <h6 className={`title-upper-blue mt-20`}>EN ESTOS MOMENTOS LAS RESERVAS NO SE ENCUENTAN HABILITADAS POR MOTIVO A SEMANA RADICAL</h6>
              </div>
              :
              <>
              <ReserveMainTitle title={status === 'activas' ? 'Mis reservas:':"Reserve:"} breadcrumb={['Inicio > ', `Reservaciones ${status ? status: ''}`]}/>
                <div className='reserve-items-container mb-30'>
                  {Object.keys(reserveOptions).map(option=><ReserveSelectionBox key={option} url={reserveOptions[option].url} name={option} icon={reserveOptions[option].icon} />)}
                </div>
              </>
            }
        </ReserveWrapper>
    )
}
export default ReserveHome;
