import { useEffect, useState } from 'react'
import { getSettings } from '../services/getSettings'

export const useSetting = () => {
  const [bloqued, setBloqued] = useState(false)
  const [loadingBloqued, setLoadingBloqued] = useState(true)

  useEffect(() => {
    setLoadingBloqued(true)
    getSettings()
    .then(data => {
      setBloqued(data.setting.blockReservations)
      setLoadingBloqued(false)
    })
    .catch(() => {
      return
    }) 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { bloqued, loadingBloqued }
}
