import React from 'react';
import {Modal, /*Button*/} from 'antd';
import {withRouter} from 'react-router-dom';
// import './ReserveSuccessModal.scss' ;
import logo from '../../../assets/logo-vaac-blue.svg';
import iconTenis from '../../../assets/icon-tenis.svg';
import iconCycling from '../../../assets/icon-cycling.svg';
import iconOthers from '../../../assets/icon-others.svg';
import iconSquash from '../../../assets/icon-squash.svg';
import {Link} from 'react-router-dom';

const icons = {
	'tenis': iconTenis,
	'cycling': iconCycling,
	'squash': iconSquash,
	'others': iconOthers,
}

function ModalError(props) {

  const closeWindow = () => {
    if(props.deleteReserveReason) return props.history.push('/reservas/lista')
    props.setShowModal(false)
  }
  
  return (
    <>
     <div className="modal-reserves">
      <Modal footer={null} visible={true} centered closable={false}>
        <div className="modal-body-reserves">
          <div className="main-modal-body-reserves--logo"><img src={logo} alt="Logo Vaac" className="modal-body-reserves--logo"/></div>
            {props.activity && <div><img src={icons[props.activity]} alt="Icono" className="modal-body-reserves--icon"/></div>}
            <h6 className={`${!props.activity && 'mt-20'} title-upper-blue`}>{props.bloquedReserves || props.deleteReserveReason ? props.content  : ''}</h6>
            {<p className="modal-body-reserves-message">{props.bloquedReserves || props.deleteReserveReason ? '' : props.content}</p>}
            <div>
            <Link className="modal-body-reserves-link" to="#" onClick={() => closeWindow()} type='link'>CERRAR VENTANA</Link>
          </div> 
        </div>
      </Modal>
     </div>     
    </>
  )
}
export default withRouter(ModalError);
