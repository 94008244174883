import React from 'react';
import './Seat.scss';

function AdminSeat(props) {
	return (
		<>
			{props.data ? 
				props.data.instructor ?
				<div className={`seat mr-5 ml-5 ${props.trx ? 'trx-admin-instructor': 'seat-admin-instructor'}`} onClick={() => props.saveSeat(props.row, props.column)}>
					<span className='seat-number-instructor'>Instructor</span>
				</div>
				:
				<div className={`seat mr-5 ml-5 ${props.trx ? 'trx-available':'seat-available'}`} onClick={() => props.saveSeat(props.row, props.column)} >
					<span className='seat-number'>{props.data.id}</span>
				</div>
				:
				<div className={`seat mr-5 ml-5 ${props.trx ? 'trx-admin-unavailable': 'seat-admin-unavailable'}`} onClick={() => props.saveSeat(props.row, props.column)}></div>
			}
		</>
	)
}

export default AdminSeat;