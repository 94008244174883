import React, { useRef, useState, useEffect } from 'react';
import { Input, Select, Button } from 'antd';
// import ModalError from '../../Molecules/ModalError/ModalError';
// import { SearchOutlined } from '@ant-design/icons';
import './ActionInput.scss';
import {useSelector} from 'react-redux';

function ActionInput(props) {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [data, setData] = useState([]);
    const [action, setAction] = useState('');
    const [cedula, setCedula] = useState('');
    // const [notFound, setNotFound] = useState(false);

    const token = useSelector(state => state.userReducer.token)
    const inputCurrent = useRef()

    // console.log('RESPONSABLE::  ', props.responsable)
    // console.log('PLAYER 2::  ', props.player2)
    // console.log('PLAYER 3::  ', props.player3)
    // console.log('PLAYER 4::  ', props.player4)

    const saveUserData = id => {
      // console.log('numberInput', props.numberInput)
        let userData;
        data.forEach(socio => {
            if (socio.IDUNICO === id) {
                userData = {
                    actionNumber: action,
                    cedula: cedula,
                    firstName: socio.NOMBRES,
                    lastName: socio.APELLIDOS ? socio.APELLIDOS : null,
                    email: socio.CORREO ? socio.CORREO : null,
                    legalId: socio.CEDULA ? socio.CEDULA : null,
                    defaulter: false, //si entra aqui es porque no esta moroso,
                    uniqueId: socio.IDUNICO,
                    parentezco: socio.PARENTESCO,
                }
            }
        })
        props.onChange(userData)
    }
    const search = (action, cedula) => {
      // console.log('Search')
        setLoading(true);
        const data = new FormData();
        data.append('ACCION', action.trim());
        data.append('CEDULA', cedula.trim().replace(/\./g, ''));
        fetch("https://vaac.dyndns.org/tisolucionesapi/socios/consultaaccion", {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: data
        })
        .then(res => res.json())
        .then(res => {
          // console.log('res', res);
          setLoading(false);
            if (res && res.AFILIADOS) {
              // console.log('res.AFILIADOS', res.AFILIADOS)
                if (res.AFILIADOS[0].MOROSIDAD === '1') {
                  props.setErrorMessage('El titular de esta acción se encuentra moroso, por lo tanto no puede ser invitado');
                  props.showError()
                } else {
                  setData(res.AFILIADOS)
                  let IDUNICORESPONSABLE = props.responsable.idUnico.length <=5 ? `00${props.responsable.idUnico}00` : props.responsable.idUnico
                  // setOptions(res.AFILIADOS.map((socio, i) => <Select.Option value={socio.IDUNICO} key={i}>{socio.CEDULA || socio.IDUNICO}</Select.Option>))
                  setOptions(
                    res.AFILIADOS
                    .filter(socio => {
                      // console.log('c/u socio::', socio)
                      // console.log('IDUNICORESPONSABLE', IDUNICORESPONSABLE)
                      if(props.player2 && props.player3 && props.player4) {
                        return (socio.IDUNICO !== IDUNICORESPONSABLE) && (socio.IDUNICO !== props.player2.uniqueId) && (socio.IDUNICO !== props.player3.uniqueId) && (socio.IDUNICO !== props.player4.uniqueId)
                      } else if(props.player2 && props.player3) {
                        return (socio.IDUNICO !== IDUNICORESPONSABLE) && (socio.IDUNICO !== props.player2.uniqueId) && (socio.IDUNICO !== props.player3.uniqueId)
                      } else if(props.player2) {
                        return (socio.IDUNICO !== IDUNICORESPONSABLE) && (socio.IDUNICO !== props.player2.uniqueId)
                      } else {
                        return socio.IDUNICO !== IDUNICORESPONSABLE
                      }
                    })
                    // .map((socio, i) => <Select.Option value={socio.IDUNICO} key={i}>{socio.CEDULA || socio.IDUNICO}</Select.Option>)
                    .map((socio, i) => <Select.Option value={socio.IDUNICO} key={i}>{socio.CEDULA || socio.IDUNICO}</Select.Option>)
                  ) 
                }
            } else {
                if (res.status === 401) {
                    //token expiro
                    props.setErrorMessage('Su sesión ha expirado, por favor haga login nuevamente')
                    props.showError()
                } else {
                    props.setErrorMessage('No se han encontrado registros con esta acción y cédula')
                    props.showError()
                }
            }
        })
        .catch(err => {
            // console.log(err)
            setLoading(false);
            props.setErrorMessage('Ha ocurrido un error consultando este socio')
            props.showError()
        })
    }

    const handleClick = () => {
      // console.log('options', options)
      // console.log('numberInput', props.numberInput)
        if (options.length > 0) {
            // console.log('entro aqui options.length > 0')
            setOptions([])
            setAction('')
            setCedula('');
            props.onChange(null)
        } else {
          // console.log('entro aqui options.length > 1')
            if (action && cedula) {
              search(action, cedula)
              // console.log('yes1')
            } else {
              props.setErrorMessage('Debe especificar un número de acción y una cédula de una persona miembro de la acción para realizar la invitación.');
              props.showError()
            }
        }
    }

    useEffect(() => {
      // console.log('ActionInput Render!!')
      if (props.defaultValue) {
        setAction(props.defaultValue.actionNumber);
        setCedula(props.defaultValue.cedula)
        search(props.defaultValue.actionNumber, props.defaultValue.cedula)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultValue])

    return (
        <div className='action-input-container'>
            {/* {options.length > 0 || notFound? */}
            {options.length > 0 ?
                /* <Select ref={inputCurrent}defaultOpen={!props.defaultValue && !notFound} defaultValue={props.defaultValue && (props.defaultValue.uniqueId)} className='action-search-field tenis-select-size small' placeholder='Seleccione la cédula del invitado' onChange={val => { */
                <Select ref={inputCurrent} defaultOpen={!props.defaultValue} defaultValue={props.defaultValue && (props.defaultValue.uniqueId)} className='action-search-field tenis-select-size small' placeholder='Seleccione la cédula del invitado' onChange={val => {
                  // console.log('inputCurrent => ', inputCurrent.current)
                  saveUserData(val)
                }}>
                  {options}
                </Select>
                :
                <>
                  <Input value={action} className='action-search-field ' placeholder='No. Acción' onChange={e=>setAction(e.target.value)} />
                  <Input value={cedula} className='action-search-field' placeholder='Cédula' onChange={e=>setCedula(e.target.value)} />
                </>
            }
           {options.length > 0 ? 
           <Button onClick={handleClick} className='button purple outline action-search-button'>
              Limpiar
            </Button>
            :
            <Button onClick={handleClick} className='button purple outline action-search-button' loading={loading}>
              Invitar
            </Button>}
        </div>
    )
}

export default ActionInput;
