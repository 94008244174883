import React, {useState} from 'react'
import {MenuOutlined} from '@ant-design/icons';
import './ToggleMenu.scss';
import ReserveMenu from '../../Molecules/ReserveMenu/ReserveMenu';
import { Drawer } from 'antd';
import { withRouter } from 'react-router-dom';

const ToggleMenu = (props) => {
  const [visible, setVisible] = useState(false);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
    <div className="toggle-menu-dashboard" onClick={() => setVisible(true)}>
      <MenuOutlined />
    </div>
    <Drawer
      width={310}
      placement="left"
      closable={true}
      onClose={onClose}
      visible={visible}
      className="drawer-dashboard"
    >
      <ReserveMenu {...props} drawer setVisible={setVisible}/>
    </Drawer>
    </>
  )
}

export default withRouter(ToggleMenu)
