import { getNotifications } from "../services/getNotifications";
import request from "../services/request";
import { 
    SAVE_USER,
    SAVE_TOKEN, 
    LOGOUT_USER,
    SAVE_ZONE,
    SAVE_NOTIFICATIONS
} from "./actions";
  
export const saveUser = user => ({
    type: SAVE_USER,
    data: user
})

export const saveToken = token => ({
	type: SAVE_TOKEN,
	data: token
})

export const logoutUser = () => async dispatch  =>{
  request('PATCH', '/logout')
  return dispatch({
    type: LOGOUT_USER,
    data: {}
  })
}

export const saveZone = () => ({
  type: SAVE_ZONE,
  data: {}
})

export const saveNotifications =  ({page})  => async dispatch => {
  const { rows } = await getNotifications({page})
  if(rows) {
    const notificationsUnread = rows.filter(notification => notification.read !== true)
    return dispatch({
      type: SAVE_NOTIFICATIONS,
      data: [rows, notificationsUnread.length]
    })
  }
}
